import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  VehiclesFeatureName,
  VehiclesModuleState,
} from "../state/vehicles.state";

export const VehiclesListRootSelector =
  createFeatureSelector<VehiclesModuleState>(VehiclesFeatureName);

export const selectVehiclesList = createSelector(
  VehiclesListRootSelector,
  (state: VehiclesModuleState) => state.vehicleListReducer,
);
