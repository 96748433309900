import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  DocumentsFeatureName,
  DocumentsModuleState,
} from "../state/documents.state";

export const TemporalPasswordRootSelector =
  createFeatureSelector<DocumentsModuleState>(DocumentsFeatureName);

export const selectTemporalPassword = createSelector(
  TemporalPasswordRootSelector,
  (state: DocumentsModuleState) => state.temporalPasswordReducer,
);
