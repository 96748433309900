/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { IDataPseBanks, IPseBanks } from "@app/models/pse";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";
import { PaymentFacade } from "./store/facade/payment.facade";

@Component({
  selector: "fp-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() requierTerms: boolean = true;
  @Input() readonlyTerms: boolean = true;
  @Input() purchaseInfoMessages: any;
  entitiesBank: IDataPseBanks[];
  @Input() title: string;
  @Input() labelBakns: string;
  @Input() typeTemplate: string = "defaul";
  @Input() labelTypePerson: string;
  @Input() onlyTermns: boolean = false;
  @Input() loadPseBanks: boolean = true;
  @Input() avalPay: boolean = true;
  @Input() set valueForm(values: any) {
    if (values) {
      if (this.requierTerms) {
        this.formPurchaseDetail.setValue({
          termsandConditions: values.termsandConditions,
          personType: values.personType,
          entities: values.entities,
          payment_method: values.payment_method,
        });
      } else {
        this.formPurchaseDetail.setValue({
          personType: values.personType,
          entities: values.entities,
          payment_method: values.payment_method,
        });
      }
      this.emitFormValid();
    }
  }
  @Input() labelTermsConditions = { title: "", description: "" };
  @Output() onChangeBank = new EventEmitter<string>();
  @Output() onChangePersonType = new EventEmitter<string>();
  @Output() onChangeReadonlyTerms = new EventEmitter<boolean>();
  @Output() onOpenModal = new EventEmitter<boolean>();
  @Output() formValid = new EventEmitter<boolean>();
  @Output() goNext: EventEmitter<any> = new EventEmitter();
  formPurchaseDetail: UntypedFormGroup;
  personTypes = [
    {
      description: "Natural",
      value: "1",
    },
    {
      description: "Jurídica",
      value: "2",
    },
  ];
  constructor(private paymentFacade: PaymentFacade, private router: Router) {}
  /**
   * load initial state, resources and form
   */
  async ngOnInit(): Promise<any> {
    this.initForm();
    if (this.loadPseBanks) {
      await this.getPseBanks();
    }
  }

  /**
   * Destroy subscriptions
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  /**
   * Init to pay form
   */
  initForm(): void {
    this.formPurchaseDetail = new UntypedFormGroup({
      personType: new UntypedFormControl("", [Validators.required]),
      entities: new UntypedFormControl("", [Validators.required]),
      payment_method: new UntypedFormControl(1, [Validators.required]),
    });
    if (this.requierTerms) {
      this.formPurchaseDetail.addControl(
        "termsandConditions",
        new UntypedFormControl(false, [
          Validators.required,
          Validators.requiredTrue,
        ]),
      );
    }
  }
  /**
   * Select PersonTyoe and set value on control to person type
   *
   * @param personType Natural or management
   */
  choosePersonType(personType: string): void {
    this.formPurchaseDetail.controls.personType.setValue(personType);
    this.onChangePersonType.emit(personType);
    this.emitFormValid();
  }
  /**
   * Selection Change
   *
   * @param entitie
   */
  selectionChange(entitie: any): void {
    this.onChangeBank.emit(entitie.target.selectedOptions[0].id);
    this.emitFormValid();
  }
  /**
   * Status Check Terms and Conditios
   */
  statusCheckTerms(): void {
    this.onChangeReadonlyTerms.emit(true);
  }
  /**
   * Open modal
   */
  openModal(): void {
    this.onOpenModal.emit(true);
  }

  /**
   * get Pse Banks Info
   */
  async getPseBanks(): Promise<any> {
    const promisePseBanks = await this.selectPseBanks$
      .pipe(take(1))
      .toPromise();
    if (promisePseBanks.data === null) {
      this.paymentFacade.getPseBanks();
      this.selectPseBanks2$.pipe(take(2)).subscribe((value) => {
        if (value && value.data) {
          this.entitiesBank = value.data;
        }
        if (value.error) {
          this.router.navigate(["/error"]);
        }
      });
    } else {
      this.entitiesBank = promisePseBanks.data;
    }
  }
  /**
   * Emit form Valid to fathers component
   */
  emitFormValid(): void {
    if (this.formPurchaseDetail.valid) {
      this.formValid.emit(true);
    } else {
      this.formValid.emit(false);
    }
  }

  next(param?: string): void {
    this.goNext.emit(param);
  }

  /**
   * Get select Pse Banks
   */
  get selectPseBanks$(): Observable<IPseBanks> {
    return this.paymentFacade.selectPseBanks$;
  }

  get selectPseBanks2$(): Observable<IPseBanks> {
    return this.paymentFacade.selectPseBanks2$;
  }
}
