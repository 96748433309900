<fp-card-body [template]="'secondTemplate'" [typeDashboard]="true" [exit]="true" class="fp-top-up" actionType="logout">
    <ng-template>
        <div class="fp-purchase-detail-container">
            <div class="title_box">
                <h1 class="fp-h1 top-up title">{{"title" | translate}}</h1>
                <h2 class="fp-body-one subtitle"> {{"subtitle" | translate}}</h2>
            </div>
            <div class="options">
                <div class="option" *ngFor="let option of options">
                    <div class="circle">
                        <img [src]="option.image" alt="icon image">
                    </div>
                    <div class="text">
                        <span>{{option.text}}</span>
                    </div>
                    <div class="right-arrow">
                        <img src="assets/images/other-options/arrow-left.svg" alt="arrow-left">
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</fp-card-body>