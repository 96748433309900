import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppFacade } from "@app/app.facade";
import { ITagInfo } from "@home/models/home";
import { TranslateService } from "@ngx-translate/core";
import { ITagActivation } from "@shared/models/tag-activation";
import { CommonsService } from "@shared/services/commons.service";
import { AuthenticationService } from "@singup/services/authentication.service";
import { Languages } from "@store/actions/app.action";
import { ModalService } from "@theme/components/modal/service/modal.service";
import {
  IVehicleInformation,
  IVehiclesList,
  IVehiclesListResponse,
} from "@vehicles/models/vehicle";
import { VehiclesFacade } from "@vehicles/store/facade/vehicles.facade";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IVehiclesInfo } from "../../../home/models/home";
import {
  IDeleteVehicleRequest,
  IVehiclesCategories,
  IVehiclesCategoriesResponse,
} from "../../models/vehicle";
import { VehiclesService } from "../../services/vehicle.service";
@Component({
  selector: "fp-list-vehicles",
  templateUrl: "./list-vehicle.component.html",
  styleUrls: ["./list-vehicle.component.scss"],
})
export class ListComponent implements OnInit, OnDestroy {
  @HostBinding("class") fpListVehicles = "fp-list-vehicles";
  @ViewChild("alertActive", { static: true }) alertActive: TemplateRef<any>;
  loadingChange: boolean = true;
  loading: boolean = true;
  vehicles: IVehiclesListResponse[];
  categories: IVehiclesCategoriesResponse[];
  category: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  error: boolean = false;
  titleModal: string = "modal.title";
  labelBtnAccept: string = "modal.btn";
  hideClose: boolean;
  messageError: string;
  itemVehicleSelected: IVehiclesListResponse;
  vehicleInfo: IVehiclesInfo;

  @ViewChild("tempError", { static: true })
  tempMessageError: TemplateRef<any>;
  constructor(
    private snackBar: MatSnackBar,
    private commonsService: CommonsService,
    private modalService: ModalService,
    private vehiclesFacade: VehiclesFacade,
    private authenticationService: AuthenticationService,
    public translate: TranslateService,
    private appFacade: AppFacade,
    private vehiclesService: VehiclesService,
  ) {}
  /**
   * destroy subscriptions
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.closeAlertActive();
    this.vehiclesFacade.vehicleListReset();
  }
  /**
   * ngOnInit to start initial values
   */
  ngOnInit(): void {
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
    this.getVehicles();
    this.snackBar.openFromTemplate(this.alertActive, {
      horizontalPosition: "center",
      panelClass: "fp-snack-modify",
      verticalPosition: "top",
    });
    this.selectVehiclesCategories();
    this.selectVehiclesList();
  }

  /**
   * Close Message
   */
  closeAlertActive(): void {
    this.snackBar.dismiss();
  }
  /**
   * get Vehicles List
   *
   * @param flag
   */
  async getVehicles(flag: boolean = false): Promise<any> {
    if (flag) {
      this.loading = true;
    }
    this.vehiclesFacade.getVehiclesList(
      await this.authenticationService.getUsername(),
    );
  }
  /**
   * select Vehicle list from state
   */
  selectVehiclesList(): void {
    this.selectVehiclesList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((listVehicles: IVehiclesList) => {
        if (listVehicles.data && !listVehicles.loading) {
          this.loading = listVehicles.loading;
          this.vehicles = listVehicles.data;
        }
        if (listVehicles.error) {
          this.loading = listVehicles.loading;
        }
      });
  }

  selectVehiclesCategories(): void {
    this.vehiclesFacade.getVehiclesCategories();
    this.selectVehicleCategory$
      .pipe(takeUntil(this.destroy$))
      .subscribe((listCategory: IVehiclesCategories) => {
        this.categories = listCategory.data;
        // this.categories.find(({shortDescription}) => shortDescription === $event.vehicle.shortDescriptionCategory).description
      });
  }
  /**
   * navigatetoVehicleCreation
   */
  navigatetoVehicleCreation(): void {
    this.commonsService.navigate("/vehicle/create");
  }
  /**
   * navigate to Recharge
   */
  navigatetoRecharge(): void {
    this.commonsService.navigate("/transactions/recharge");
  }

  /**
   * Open modal
   *
   * @param id string id element Html
   */
  openModal(id: string): void {
    this.closeAlertActive();
    this.modalService.open(id);
  }
  /**
   * Open modal
   *
   * @param id string id element Html
   */
  closeModal(id: string): void {
    this.modalService.close(id, false);
  }
  /**
   * Change Status on tag
   *
   * @param tagId tag Id
   */
  async changeStatusTag(tagId: ITagInfo): Promise<any> {
    this.loadingChange = true;
    this.error = false;
    let status: "ACTIVE" | "INACTIVE";
    let service = "PARKING";
    if (tagId.currentStatus !== 1) {
      status = "ACTIVE";
      this.messageError = "error_message.activate";
    } else {
      status = "INACTIVE";
      this.messageError = "error_message.inactive";
    }
    try {
      const infoActivation: ITagActivation = {
        idClient: tagId.idClient,
        idTag: tagId.id,
        status,
        service,
      };
      const respActivateTag =
        await this.commonsService.callTagActivationService(infoActivation);
      if (!respActivateTag.error) {
        if (tagId.currentStatus !== 1) {
          this.titleModal = "modal.title";
          this.labelBtnAccept = "modal.btn";
          this.hideClose = false;
        } else {
          this.titleModal = "modal.title_inactive";
          this.labelBtnAccept = "modal.btn_inactive";
          this.hideClose = true;
        }
        this.openModal("fp-activation-progress");
        this.getVehicles();
      } else {
        this.error = true;
        this.showError();
      }
    } catch {
      this.error = true;
      this.showError();
    } finally {
      this.closeModal("fp-vehicles-management");
      this.loadingChange = false;
    }
  }
  /**
   * show Error
   */
  showError(): void {
    this.snackBar.openFromTemplate(this.tempMessageError, {
      horizontalPosition: "center",
      panelClass: "fp-snack-modify",
      verticalPosition: "top",
    });
  }
  /**
   * redirect to Buy tag form
   *
   * @param $event event with vehicle Information
   */
  goTobuyTag($event: IVehicleInformation) {
    this.commonsService.redirectToTagWithVehicleInformation($event);
  }

  openModalManagement($event: boolean, itemVehicle: IVehiclesListResponse) {
    if ($event) {
      this.openModal("fp-vehicles-management");
      this.itemVehicleSelected = itemVehicle;
    }
  }

  deleteVehicleShowModal($event: IVehiclesInfo): void {
    this.vehicleInfo = $event;
    this.category = this.categories.find(
      ({ shortDescription }) =>
        shortDescription === $event.vehicle.shortDescriptionCategory,
    ).description;
    this.modalService.open("fp-delete-vehicle");
    this.closeModal("fp-vehicles-management");
  }

  async deleteVehicleClose($event: {
    payload: IDeleteVehicleRequest;
    typeAction: string;
  }) {
    this.closeModal("fp-delete-vehicle");
    if ($event.typeAction === "accept") {
      try {
        this.appFacade.setLoaderShow({
          type: "GENERAL",
        });
        await this.vehiclesService.deleteVehicle($event.payload).toPromise();
        this.getVehicles();
        this.selectVehiclesList();
      } catch (error) {
        this.messageError =
          "No fue posible eliminar el vehiculo, inténtelo más tarde";
        this.showError();
      } finally {
        this.appFacade.setLoaderHide();
      }
    }
  }

  get selectVehiclesList$(): Observable<IVehiclesList> {
    return this.vehiclesFacade.selectVehiclesList$;
  }

  /**
   * get data of language selector
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  get selectVehicleCategory$(): Observable<IVehiclesCategories> {
    return this.vehiclesFacade.selectVehiclesCategories$;
  }
}
