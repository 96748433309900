<div class="container-info" [ngClass]="{'hide-close': !showClose}" *ngIf="showInfo">
    <div style="height: 28px; width: 28px;">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fill-rule="evenodd">
                <path class="type-info" [ngClass]="{'info': typeClass === 'info'}"
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"
                    transform="translate(-32 -216) translate(16 200) translate(16 16)" />
                <path class="icon-type" [ngClass]="{'info': typeClass === 'info'}"
                    d="M12 11.25c.38 0 .693.282.743.648l.007.102v5c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648L11.25 17v-4.25H11c-.38 0-.693-.282-.743-.648L10.25 12c0-.38.282-.693.648-.743L11 11.25h1zm-.501-4.75l.117.007c.497.057.884.48.884.993 0 .552-.448 1-1 1s-1-.448-1-.995c-.004-.517.387-.944.89-1l.109-.005z"
                    transform="translate(-32 -216) translate(16 200) translate(16 16)" />
            </g>
        </svg>
    </div>
    <div class="info-message" [innerHtml]="messageSanitizer"></div>
    <svg (click)="closeInfoSpam()" *ngIf="showClose" class="close" xmlns="http://www.w3.org/2000/svg" width="24"
        height="24" viewBox="0 0 24 24">
        <g fill="none" fill-rule="evenodd">
            <g class="close-type" [ngClass]="{'info': typeClass === 'info'}">
                <path
                    d="M8.446 7.397l.084.073L12 10.939l3.47-3.47c.293-.292.767-.292 1.06 0 .267.267.29.684.073.977l-.073.084L13.061 12l3.47 3.47c.292.293.292.767 0 1.06-.267.267-.684.29-.977.073l-.084-.073L12 13.061l-3.47 3.47c-.293.292-.767.292-1.06 0-.267-.267-.29-.684-.073-.977l.073-.084L10.939 12l-3.47-3.47c-.292-.293-.292-.767 0-1.06.237-.237.593-.282.875-.137l.102.064z"
                    transform="translate(-326 -216) translate(16 200) translate(310 16)" />
            </g>
        </g>
    </svg>
</div>