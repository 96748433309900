import { Component, EventEmitter, Input, Output } from "@angular/core";

export type SnackMessageOptions =
  | "error"
  | "info"
  | "warning"
  | "infoOnlyDescription";
@Component({
  selector: "fp-snack-message",
  templateUrl: "./snack-message.component.html",
  styleUrls: ["./snack-message.component.scss"],
})
export class SnackMessageComponent {
  @Input() type: SnackMessageOptions;
  @Input() title: string;
  @Input() description: string;
  @Input() icono: string;
  @Output() snackMessageEvent: EventEmitter<string> = new EventEmitter();

  constructor() {}

  closeMessage() {
    this.snackMessageEvent.emit("close");
  }
}
