import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CommonsService } from "@shared/services/commons.service";
import { FpCardBodyComponent } from "./body/card-body.component";
import { FpCardComponent } from "./card.component";

const FP_CARD_COMPONENTS = [FpCardBodyComponent, FpCardComponent];

@NgModule({
  imports: [CommonModule],
  declarations: [...FP_CARD_COMPONENTS],
  exports: [...FP_CARD_COMPONENTS],
  providers: [CommonsService],
})
export class FpCardModule {}
