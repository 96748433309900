<div class="fp-card-detail">
    <div *ngIf="!onlyTermns" class="fp-card-detail-header">
        <span>{{title}}</span>
    </div>
    <div class="fp-card-detail-body">
        <div class="fp-col">
            <ng-container [ngSwitch]="typeTemplate">
                <ng-container *ngSwitchCase="'defaul'">
                    <ng-container *ngIf="!onlyTermns">
                        <div class="conten-input-document">
                            <button class="fp-btn-purchase-option fp-pse space" type="button" id="type_payment_method"
                                [class.selected-option]="this.formPurchaseDetail.controls.payment_method.value === 1">
                                PSE
                                <span class="radio-btn-container">
                                    <img src="assets/images/common/pse.svg" alt="logo pse">
                                </span>
                            </button>
                            <button *ngIf="avalPay" (click)="openModal()" class="fp-btn-purchase-option fp-pse space"
                                type="button" id="type_payment_method">
                                Tarjeta de crédito
                                <span class="btn-avalpay-container">
                                    <img src="assets/images/common/aval-pay.svg" alt="logo avalPay">
                                </span>
                            </button>
                        </div>
                        <div class="form-group">
                            <label class="form-label">{{labelTypePerson}}</label>
                            <div class="conten-input-document">
                                <ng-container *ngFor="let personType of personTypes; let itype= index">
                                    <button class="fp-btn-purchase-option" type="button" id="type{{personType.value}}"
                                        [class.selected-option]="this.formPurchaseDetail.controls.personType.value === personType.value"
                                        (click)="choosePersonType(personType.value)">
                                        {{personType.description}}
                                        <span class="radio-btn-container">
                                            <fp-radiobutton [formControl]="formPurchaseDetail.controls['personType']"
                                                name="personType{{personType.value}}" [value]="personType.value"
                                                inputId="personType{{personType.value}}">
                                            </fp-radiobutton>
                                        </span>
                                    </button>
                                </ng-container>
                            </div>
                            <div class="space-inputs-small"></div>
                        </div>
                        <div class="purchase-select">
                            <div class="select-content">
                                <label class="form-label">{{labelBakns}}</label>

                                <select matNativeControl class="select-banks"
                                    [class.error-select]="formPurchaseDetail.controls.entities.invalid 
                        && (formPurchaseDetail.controls.entities.touched || formPurchaseDetail.controls.entities.dirty )"
                                    [class.empity]="this.formPurchaseDetail.controls.entities.value === ''"
                                    [formControl]="formPurchaseDetail.controls['entities']"
                                    placeholder="Selecciona una entidad" (change)="selectionChange($event)">
                                    <option value="" selected disabled>Selecciona una entidad</option>
                                    <option *ngFor="let entitie of entitiesBank" [value]="entitie.bankId"
                                        [id]="entitie.name">
                                        {{entitie.name}}</option>
                                </select>
                                <img src="assets/images/select/icon.svg" alt="down">
                            </div>
                            <div class="p-absolute">
                                <span class="error-message"
                                    *ngIf="formPurchaseDetail.controls.entities.invalid  &&
                                   (formPurchaseDetail.controls.entities.touched || formPurchaseDetail.controls.entities.dirty )">
                                    {{ purchaseInfoMessages }}
                                </span>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row-terms" *ngIf="requierTerms">
                        <fp-checkbox chkboxId="chkboxtermsTag" [readonly]="readonlyTerms" name="checktermsTag"
                            inputId="checktermsTag" binary="true" (click)="statusCheckTerms()"
                            [formControl]="formPurchaseDetail.controls['termsandConditions']">
                        </fp-checkbox>
                        <a class="checkbox-obligatori">{{labelTermsConditions.title}} <u class="fp-btn-link"
                                (click)="openModal()"
                                id="link-termins-conditions-tag">{{labelTermsConditions.description}}</u></a>
                    </div>

                </ng-container>

                <ng-container *ngSwitchCase="'paymentMethods'">
                    <ng-container *ngIf="!onlyTermns">
                        <div class="conten-input-document">
                            <button class="fp-btn-purchase-option fp-pse space" type="button" id="type_payment_method">
                                PSE
                                <span class="radio-btn-container">
                                    <img src="assets/images/common/pse.svg" alt="logo pse">
                                </span>
                            </button>
                            <button (click)="openModal()" class="fp-btn-purchase-option fp-pse space"
                                type="button" id="type_payment_method">
                                Tarjeta de crédito
                            </button>
                        </div>
                    </ng-container>

                </ng-container>


            </ng-container>
        </div>
    </div>
</div>