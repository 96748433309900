import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TagActivationGuard } from "./guards/tag-activation.guard";
import { TagGuard } from "./guards/tag.guard";

export const tagRootRoute = "tag";
const routes: Routes = [
  {
    path: "",
    redirectTo: "shipping-address",
    pathMatch: "full",
  },

  {
    path: "buy-tag",
    loadChildren: () =>
      import("./modules/buy-tag/buy-tag.module").then((m) => m.BuyTagModule),
  },
  {
    path: "shipping-address",
    loadChildren: () =>
      import("./modules/shipping-address/shipping-address.module").then(
        (m) => m.ShippingAddressModule,
      ),
    canActivate: [TagGuard],
  },
  {
    path: "purchase-detail",
    loadChildren: () =>
      import("./modules/purchase-detail/purchase-detail.module").then(
        (m) => m.PurchaseDetailModule,
      ),
    canActivate: [TagGuard],
  },
  {
    path: "activation",
    loadChildren: () =>
      import("./modules/activation/activation.module").then(
        (m) => m.ActivationModule,
      ),
    canActivate: [TagActivationGuard],
  },
  {
    path: "successful-activation",
    loadChildren: () =>
      import(
        // eslint-disable-next-line comma-dangle
        "./modules/successful-activation/successful-activation.module"
      ).then((m) => m.SuccessfulActivationModule),
    canActivate: [TagActivationGuard],
  },
  {
    path: "**",
    redirectTo: "shipping-address",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TagRoutingModule {}
