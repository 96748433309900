import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TagFeatureName, TagModuleState } from "../state/tag.state";

export const TagActivationRootSelector =
  createFeatureSelector<TagModuleState>(TagFeatureName);

export const selectTagActivationpages = createSelector(
  TagActivationRootSelector,
  (state: TagModuleState) => state.tagActivationPagesReducer,
);
