import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TransactionsService {
  /**
   * Constructor
   *
   * @param http hhtp Client
   */
  constructor(private http: HttpClient) {}
  getTransactionReceipt(transactionId: number | string): Observable<any> {
    return this.http.get(
      environment.api_key.payments.back_url +
        environment.endpoints.recepit.init +
        environment.endpoints.recepit.specific.transactionId +
        transactionId,
      {
        params: new CustomHttpParams("api-pn-payments-apiKey-token"),
      },
    );
  }
}
