import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ItemListVehicleComponent } from "./item-list-vehicle.component";

@NgModule({
  declarations: [ItemListVehicleComponent],
  imports: [CommonModule, FormsModule, MatTooltipModule],
  exports: [ItemListVehicleComponent],
})
export class ItemListVehicleModule {}
