import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(private http: HttpClient, private router: Router) {}
  /**
   * get available Entities and Financial Products
   */
  getReports(accountId: string): Observable<any> {
    return this.http.get(
      environment.api_key.publicPN.back_url +
        environment.endpoints.coreReports.init +
        environment.endpoints.coreReports.specific.consultReport +
        `?accountId=${accountId}`,
      {
        params: new CustomHttpParams("api-pn-shared-apiKey-token"),
      },
    );
  }
}
