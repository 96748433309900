import { createAction, props } from "@ngrx/store";
import { IProspectsInvalidRequest, IRegister } from "@register/models/register";

export const enum TypeActions {
  ProspectLoad = "[PROSPECT Invalid] Invalid PROSPECT loaded",
  UpdateNumber = "[REGISTER Update] Upadte number phone",
  RegisterLoad = "[REGISTER User] user to register loaded",
  resetUserDataString = "[REGISTER User] reset user data",
}

export const registerUser = createAction(
  TypeActions.RegisterLoad,
  (user: IRegister) => ({ user }),
);
export const prospectsInvalidLoad = createAction(
  TypeActions.ProspectLoad,
  (data: IProspectsInvalidRequest) => data,
);

export const resetUserData = createAction(TypeActions.resetUserDataString);

export const updateNumerPhone = createAction(
  TypeActions.UpdateNumber,
  (phone: string) => ({ phone }),
);

export const prospectsInvalid = createAction(
  "[PROSPECT Invalid] prospect validate save",
  props<{
    identificationNumber: string;
    identificationNumberType: string;
    source: string;
    email: string;
    phoneNumber: string;
    invalid: boolean;
  }>(),
);

export const prospectsInvalidError = createAction(
  "[PROSPECT Invalid] iprospect validate error",
  props<{ error }>(),
);
export const prospectsInvalidReset = createAction(
  "[PROSPECT Invalid] iprospect validate reset data",
);
