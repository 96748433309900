import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { ITransactionRequest } from "@tag/models/recharge";
import { Observable } from "rxjs";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class PseService {
  /**
   * Constructor
   *
   * @param http http Client
   */
  constructor(private http: HttpClient) {}
  /**
   * get Banks
   */
  getBanks(): Observable<any> {
    return this.http.get(
      environment.api_key.payments.back_url +
        environment.endpoints.pse.init +
        environment.endpoints.pse.specific.banks,
      {
        params: new CustomHttpParams("api-pn-payments-apiKey-token"),
      },
    );
  }
  /**
   * post PSE Transaction
   *
   * @param infoTransaction object with transaction PSE properties
   */
  postTransaction(infoTransaction: ITransactionRequest): Observable<any> {
    return this.http.post(
      environment.api_key.payments.back_url +
        environment.endpoints.pse.init +
        environment.endpoints.pse.specific.transaction,
      infoTransaction,
      {
        params: new CustomHttpParams("api-pn-payments-apiKey-token"),
      },
    );
  }

  /**
   * get PSE status Transaction
   *
   * @param url object with transaction PSE status
   */
  getTransaction(url: string): Observable<any> {
    return this.http.get(
      environment.api_key.payments.back_url +
        environment.endpoints.pse.init +
        environment.endpoints.pse.specific.statusTransaction +
        url,
      {
        params: new CustomHttpParams("api-pn-payments-apiKey-token"),
      },
    );
  }
}
