import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  TransactionsFeatureName,
  TransactionsModuleState,
} from "../state/statusTransaction.state";

export const statusTransactionRootSelector =
  createFeatureSelector<TransactionsModuleState>(TransactionsFeatureName);

export const selectStatusTransaction = createSelector(
  statusTransactionRootSelector,
  (state: TransactionsModuleState) => state.statusTransactionReducer,
);
