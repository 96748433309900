<div class="container-card-header" *ngIf="showBackButton === 'visible'"
    [class.transparent]="template ==='fourdTemplate'">
    <div class="card-header">
        <div class="header-type-tow" [class.backOption]="!backOption" [class.menuShow]="menuShow">
            <div class="d-flex" *ngIf="backOption" (click)="back()">
                <img src="assets/images/close/icon-back.svg" alt="cerrar vista">
                <span class="span-exit">Volver</span>
            </div>
            <div class="d-flex" *ngIf="close" (click)="rigthAction()">
                <span>Cerrar</span>
                <img src="assets/images/close/close-vista.svg" alt="cerrar vista">
            </div>
            <div class="d-flex" *ngIf="exit" (click)="rigthAction()">
                <span class="span-exit block">Salida segura</span>
                <img src="assets/images/common/icon-interface-logout.svg" alt="secure signout">
            </div>
        </div>
        <div class="content-balls-fourd" *ngIf="template ==='fourdTemplate'">
            <div class="circulo2-second"></div>
            <div class="circulo"></div>
        </div>
    </div>
</div>
<div class="container-register" *ngIf="template === 'firstTemplate'">
    <img class="logo-facilpass" src="assets/images/logo/logo.svg" alt="">
    <div class="container-mask">
        <div class="container-form">
            <div class="container-close-register"> 
            </div>
            <div class="form">
                <div class="content-form">
                    <div class="content-balls">
                        <div class="circulo2"></div>
                        <div class="circulo"></div>
                    </div>
                    <ng-template *ngTemplateOutlet="tplRef"></ng-template>
                    <div class="circulo-cuarto"></div>
                    <div class="content-balls2">
                        <div class="circulo3"></div>
                        <div class="circulo4"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="circulo-borde" *ngIf="template === 'firstTemplate'"></div>

<div class="container-register-second" *ngIf="template === 'secondTemplate'">
    <div class="header-temporal">
        <div class="content-balls-second">
            <div class="circulo2-second"></div>
            <div class="circulo"></div>
        </div>
    </div>
    <div class="container-second">
        <div class="container-form-second">
            <div class="line" [style.visibility]="showBackButton">

            </div>
            <div class="form-second" [class.type-dashboard]="typeDashboard">
                <div class="content-form-second">
                    <ng-template *ngTemplateOutlet="tplRef"></ng-template>
                </div>
            </div>
        </div>
    </div>
    <div class="content-balls2-second">
        <div class="circulo3"></div>
        <div class="circulo4-second"></div>
    </div>
</div>
<div class="circulo-borde-second" *ngIf="template === 'secondTemplate'"></div>

<div class="container-register third" *ngIf="template === 'thirdTemplate'">
    <div class="content-balls-second third">
        <div class="circulo2-second"></div>
        <div class="circulo"></div>
    </div>
    <img class="logo-facilpass" src="assets/images/logo/logo.svg" alt="">
    <div class="container-mask-third">
        <div class="container-form">
            <div class="form-third">
                <div class="content-form-third">

                    <ng-template *ngTemplateOutlet="tplRef"></ng-template>
                    <div class="circulo-cuarto"></div>
                    <div class="content-balls2-second-third">
                        <div class="circulo3"></div>
                        <div class="circulo4-second"></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="container-ngcaptcha">
        <div class="internal">
            <span>
                Este sitio está protegido por reCAPTCHA, y para su utilización aplican las
            </span>
            <a #elementForScroll class="fp-btn-link a-link-terms" href="https://policies.google.com/privacy"
                target="_blank" rel="noopener noreferrer">
                <u>políticas de privacidad</u>
            </a>
            <span>
                y los
            </span>
            <a #elementForScroll class="fp-btn-link a-link-terms" href="https://policies.google.com/terms"
                target="_blank" rel="noopener noreferrer">
                <u>términos de servicio de Google.</u>
            </a>
            <div class="space"></div>
            <span>V.3.0.</span>
        </div>
    </div>

</div>
<div class="circulo-borde-third" *ngIf="template === 'thirdTemplate'"></div>

<div *ngIf="template ==='fourdTemplate'">
    <ng-template *ngTemplateOutlet="tplRef"></ng-template>
    <div class="circulo-borde-four"></div>
</div>