import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { movementsListRoot } from "./modules/list/movements-list-routing.module";
import { reportsRootRoute } from "./modules/reports/reports-routing.module";

export const movementsRootRoute = "movements";
const routes: Routes = [
  {
    path: "",
    redirectTo: movementsListRoot,
    pathMatch: "full",
  },
  {
    path: movementsListRoot,
    loadChildren: () =>
      import("./modules/list/movements-list.module").then(
        (m) => m.MovementsListModule,
      ),
  },
  {
    path: reportsRootRoute,
    loadChildren: () =>
      import("./modules/reports/reports.module").then((m) => m.ReportsModule),
  },
  {
    path: "**",
    redirectTo: movementsRootRoute,
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MovementsRoutingModule {}
