<div class="payment-options">
    <h1 class="fp-h1 welcome-title">
        {{title}} <br class="welcome-separator"> {{('plate' | translate:{ 'plate': plate? plate : ''})}}
    </h1>
    <div class="fp-page-space"></div>

    <div *ngIf="showBanner" class="container-info clickable" id="Banner_credit_card" (click)="navigatePublicPage()">

        <div>
            <img class="imc-cards" src="assets/images/vehicle/mc-cards.svg" 
            alt="Como sacar la tarjeta de crédito CarroYa / FacilPass Av villas. Aquí puedes encontrar cual es la mejor tarjeta de crédito en Colombia para conductores">
        </div>
        <div class="info-message">
            <div [innerHtml]="messageSanitizer">
            </div>
            <div class="right">
                {{'message2' | translate}}
            </div>
        </div>
        <div>
            <img class="icon-snack-message-back" id="icon-snack-message-back"
                src="assets/images/vehicle/right-arrow.svg" alt="Navegar">
        </div>

    </div>

    <div class="fp-page-space"></div>

    <div class="fp-card-detail">
        <div class="fp-card-detail-header detail-options-header">
            <span>{{'payment_options.title_options' | translate}}</span>
        </div>
        <div class="fp-card-detail-body">
            <div class="fp-col">
                <div class="conten-input-document">
                    <button class="fp-btn-purchase-option fp-pse space" (click)="goToShippingTag()" type="button"
                        id="btn-pse">
                        {{'payment_options.pse' | translate}}
                        <span class="radio-btn-container">
                            <img src="assets/images/common/pse.svg" alt="logo pse">
                        </span>
                    </button>
                    <button class="fp-btn-purchase-option fp-pse space" (click)="openModal('fp-gou-redirect-modal')"
                        type="button" id="btn-credit">
                        {{'payment_options.credit' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="fp-page-space"></div>

    <div *ngIf="haveTag" class="form-group">
        <div class="form-group-submit">

            <button class="submit-btn fp-btn-secondary" type="button" (click)="goToShippingTag('activationTag')"
                id="btnActivationTag">
                {{'have_tag' | translate}}
            </button>
        </div>
    </div>
    <div #btnContinue>
        <div class="continue" id="returnLabelBack" (click)="openModal('fp-modal-example-tag')">
            <button class="fp-btn-ghost" type="button" id="btnExample">
                {{'what_tag' | translate}}
            </button>
        </div>
    </div>

</div>

<fp-example-tag></fp-example-tag>
<fp-gou-redirect (itemClickActive)="navigateGou()"></fp-gou-redirect>