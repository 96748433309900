import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { hmrBootstrap } from "./hmr";

declare const module: any;

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module.hot) {
    hmrBootstrap(module, bootstrap);
  } else {
    alert("HMR is not enabled for webpack-dev-server!, run with ng serve");
  }
} else {
  bootstrap()
    .then(() => {
      if ("serviceWorker" in navigator && environment.swpwa) {
        navigator.serviceWorker
          .register("/fp-sw.js", {
            scope: "/",
          })
          .then((reg) => {
            reg.addEventListener("updatefound", () => {
              const newWorker = reg.installing;
              newWorker.addEventListener("statechange", () => {
                switch (newWorker.state) {
                  case "installed":
                    if (navigator.serviceWorker.controller) {
                      newWorker.postMessage({ action: "skipWaiting" });
                    }
                    break;
                }
              });
            });
          })
          .catch((err) =>
            // eslint-disable-next-line no-console
            console.log("ServiceWorker registration failed: ", err),
          );
        navigator.serviceWorker.addEventListener(
          "controllerchange",
          (event) => {
            window.location.reload();
          },
        );
      }
    })
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));
}
