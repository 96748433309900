import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ILanguage } from "../reducers/app.reducer";
import {
  AppFeatureName,
  IdentificationTypesFetureName,
  IIdentifiTypesState,
} from "../state/app.state";

export const LanguageRootSelector =
  createFeatureSelector<ILanguage>(AppFeatureName);

export const IdentificacionRootSelector =
  createFeatureSelector<IIdentifiTypesState>(IdentificationTypesFetureName);

export const selectLanguage = createSelector(
  LanguageRootSelector,
  (state: ILanguage) => state.language,
);

export const selectIdentificationTypes = createSelector(
  IdentificacionRootSelector,
  (state: IIdentifiTypesState) => state,
);
