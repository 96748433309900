import { IPages } from "@app/models/pages";
import { signInPagesConst } from "@core/constants/pages.constant";
import { IForgotPassword, ITimestamp } from "@signin/models/forgot-password";
import { ITemporalPassword } from "@singup/models/sing-up";
import { ITimerCode } from "@singup/modules/otp-code/models/otp-code";

/**
 * Set a Name to read chart redux
 */
export const SignInFeatureName = "SignInState";

/**
 * Grouping to SignInState
 */
export type SignInModuleState = Readonly<{
  forgotPasswordReducer: IForgotPassword;
  singInPagesReducer: IPages;
  signInOtpCodeTimerReducer: ITimerCode;
  signInTemporalPasswordReducer: ITemporalPassword;
  signInTimestampReducer: ITimestamp;
}>;

/**
 * Initialize state to Vehicle plate
 */
export const initialStateForgotPassword: IForgotPassword = {
  identificationType: null,
  identificationNumber: null,
  destination: null,
  recover: false,
};

export const initialStateSignInPages: IPages = {
  pages: [signInPagesConst[0]],
};

export const initialStateTemporalPassword: ITemporalPassword = {
  temporalPassword: null,
};

export const initialStateTimestamp: ITimestamp = {
  timestamp: null,
};
export const initialStateOtpCodeTimer: ITimerCode = {
  time: null,
};
