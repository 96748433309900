<div class="fp-movement-row">

    <div class="fp-movement-icon" [ngClass]="transactionClass">
        <img [src]="image" alt="tag compra">
    </div>
    <div class="fp-movement-description">
        <span *ngIf="movement?.concept">{{ movement?.concept}} <br>
            <span class="small-movement">
                {{detail}}
            </span>
        </span>
    </div>
    <div class="text-right">
        <span class="fp-amount" [ngClass]="transactionClass">
            {{signo}} ${{ movement?.amount | currency : 'COP' : '' : '1.0-0' }}
            <br>
            <span class="small-movement date" *ngIf="movement">
                {{ movement?.dateTransaction?.substring(0, 19) | hours:'modificado' }}
            </span>
        </span>
    </div>
    <div *ngIf="typeTemplate !== 'firstTemplate'" class="card-arrow" (click)="showDetail()">
        <img [class.rotar]="expanded" src="assets/images/transactions/icon-interface-arrow-down-small.svg"
            alt="arrow-down icon">
    </div>
</div>
<div class="detalle-movement" *ngIf="typeTemplate !== 'firstTemplate'" [class.d-item-show]="expanded">
    <div class="column">
        <div class="status-line">
            <hr>
        </div>
        <ng-container [ngSwitch]="movement?.transactionType">
            <ng-container *ngSwitchCase="'1'">
                <span> <span class="bold-detail">Placa:</span> {{' '+movement?.data?.plate | uppercase}}</span>
                <span *ngIf="movement?.data?.faceId"><span class="bold-detail">Facial: </span>{{' '+movement?.data?.faceId}}</span>
                <span><span class="bold-detail">Hora: </span> {{ movement?.dateTransaction?.substring(0,
                    19) | hours
                    }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'2'">
                <span><span class="bold-detail">Banco: </span>{{movement?.data?.bankDescription}}</span>
                <span><span class="bold-detail">Nure: </span>{{movement?.data?.nure}}</span>
                <span><span class="bold-detail">Hora: </span>{{ movement?.dateTransaction?.substring(0,
                    19) | hours
                    }}</span>
            </ng-container>

            <ng-container *ngSwitchCase="'3'">
                <span><span class="bold-detail">Placa: </span>{{movement?.data?.plate}}</span>
                <span><span class="bold-detail">TAG: </span>{{'****'+movement?.data?.tid?.substr(-6)}}</span>
                <span><span class="bold-detail">Carril: </span>{{movement?.data?.laneId}}</span>
                <span><span class="bold-detail">Fecha: </span>{{ movement?.dateTransaction?.substring(0,
                    19) | hours:'modified'
                    }}</span>
                <span><span class="bold-detail">Hora: </span>{{ movement?.dateTransaction?.substring(0,
                    19) | hours
                    }}</span>
                <span><span class="bold-detail">Categoría: </span>{{movement?.data?.categoryDAC}}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'4'">
                <span><span class="bold-detail">Placa: </span>{{movement?.data?.plate}}</span>
                <span><span class="bold-detail">TAG: </span>{{'****'+movement?.data?.tid?.substr(-6)}}</span>
                <span><span class="bold-detail">Carril: </span>{{movement?.data?.laneId}}</span>
                <span><span class="bold-detail">Fecha: </span>{{ movement?.dateTransaction?.substring(0,
                    19) | hours:'modified'
                    }}</span>
                <span><span class="bold-detail">Hora: </span>{{ movement?.dateTransaction?.substring(0,
                    19) | hours
                    }}</span>
                <span><span class="bold-detail">Categoría: </span>{{movement?.data?.categoryDAC}}</span>
                <span><span class="bold-detail">Nº Transación:
                    </span>{{'****'+movement?.data?.tollReferenceCode?.substr(-6)}}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'5'">
                <span><span class="bold-detail">Placa: </span>{{movement?.data?.plate}}</span>
                <span><span class="bold-detail">TAG: </span>{{'****'+movement?.data?.tid?.substr(-6)}}</span>
                <span><span class="bold-detail">Carril: </span>{{movement?.data?.laneId}}</span>
                <span><span class="bold-detail">Fecha: </span>{{ movement?.dateTransaction?.substring(0,
                    19) | hours:'modified'
                    }}</span>
                <span><span class="bold-detail">Hora: </span>{{ movement?.dateTransaction?.substring(0,
                    19) | hours
                    }}</span>
                <span><span class="bold-detail">Categoría: </span>{{movement?.data?.categoryDAC}}</span>
                <span><span class="bold-detail">Nº Transación:
                    </span>{{'****'+movement?.data?.tollReferenceCode?.substr(-6)}}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'12'">
                <span *ngIf="movement?.data?.faceId"><span class="bold-detail">Facial: </span>{{' '+movement?.data?.faceId}}</span>
                <span> <span class="bold-detail">Placa:</span> {{' '+movement?.data?.plate | uppercase}}</span>
                <span><span class="bold-detail">Hora: </span> {{ movement?.dateTransaction?.substring(0,
                    19) | hours
                    }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'13'">
                <span> <span class="bold-detail">Placa:</span> {{' '+movement?.data?.plate | uppercase}}</span>
                <span *ngIf="movement?.data?.faceId"><span class="bold-detail">Facial: </span>{{' '+movement?.data?.faceId}}</span>
                <span><span class="bold-detail">Hora: </span> {{ movement?.dateTransaction?.substring(0,
                    19) | hours
                    }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'15'">
                <span><span class="bold-detail">Hora: </span>{{ movement?.dateTransaction?.substring(0,
                    19) | hours
                    }}</span>
                <span><span class="bold-detail">Nure: </span>{{movement?.data?.nure}}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'16'">
                <span><span class="bold-detail">Hora: </span>{{ movement?.dateTransaction?.substring(0,
                    19) | hours
                    }}</span>
                <span><span class="bold-detail">Banco: </span>{{movement?.data?.bankDescription}}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'17'">
              <span><span class="bold-detail">Fecha: </span>{{ movement?.dateTransaction?.substring(0,
                19) | hours:'modified'
                }}</span>
              <span><span class="bold-detail">Hora: </span>{{ movement?.dateTransaction?.substring(0,
                19) | date: 'h:mm:ss a'
                }}</span>
              <span><span class="bold-detail">Fecha de entrada: </span>{{ movement?.data?.entranceDate?.substring(0,
                19) | hours:'modified'
                }}</span>
              <span><span class="bold-detail">Hora de entrada: </span>{{ movement?.data?.entranceDate?.substring(0,
                19) | date: 'h:mm:ss a'
                }}</span>
              <span><span class="bold-detail">Parqueadero: </span>{{ movement?.station?.name }}</span>
              <span><span class="bold-detail">Placa: </span>{{ movement?.data.plate }}</span>
              <span><span class="bold-detail">Valor de la transacción: </span> ${{ movement?.amount | currency : 'COP' : '' : '1.0-0' }}</span>
              <span><span class="bold-detail">Valor de la comisión: </span> ${{ movement?.data?.commission | currency : 'COP' : '' : '1.0-0' }}</span>
              <span><span class="bold-detail">Valor del iva: </span> ${{ movement?.data?.tax | currency : 'COP' : '' : '1.0-0' }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'18'">
                <span><span class="bold-detail">Fecha: </span>{{ movement?.dateTransaction?.substring(0,
                  19) | hours:'modified'
                  }}</span>
                <span><span class="bold-detail">Hora: </span>{{ movement?.dateTransaction?.substring(0,
                  19) | date: 'h:mm:ss a'
                  }}</span>
                <span><span class="bold-detail">Fecha de entrada: </span>{{ movement?.data?.entranceDate?.substring(0,
                  19) | hours:'modified'
                  }}</span>
                <span><span class="bold-detail">Hora de entrada: </span>{{ movement?.data?.entranceDate?.substring(0,
                  19) | date: 'h:mm:ss a'
                  }}</span>
                <span><span class="bold-detail">Lavadero: </span>{{ movement?.station?.name }}</span>
                <span><span class="bold-detail">Placa: </span>{{ movement?.data.plate }}</span>
                <span><span class="bold-detail">Valor de la transacción: </span> ${{ movement?.amount | currency : 'COP' : '' : '1.0-0' }}</span>
                <span><span class="bold-detail">Valor de la comisión: </span> ${{ movement?.data?.commission | currency : 'COP' : '' : '1.0-0' }}</span>
                <span><span class="bold-detail">Valor del iva: </span> ${{ movement?.data?.tax | currency : 'COP' : '' : '1.0-0' }}</span>
              </ng-container>
            <ng-container *ngSwitchDefault>
                <span><span class="bold-detail">Hora: </span>{{ movement?.dateTransaction?.substring(0,
                    19) | hours
                    }}</span>
            </ng-container>
        </ng-container>
    </div>
</div>
<div class="t-center" *ngIf="typeTemplate !== 'firstTemplate'" [class.movement-arrow]="expanded">
    <img [class.rotar]="expanded" src="assets/images/transactions/icon-interface-arrow-down-small.svg"
        alt="arrow-down icon" (click)="showDetail()">
</div>
<div class="status-line" *ngIf="typeTemplate !== 'firstTemplate' && showDivider">
    <hr>
</div>
