import { IModules } from "@app/models/modules";
import { createAction } from "@ngrx/store";

export const enum TypeModulesActions {
  modulesString = "[MODULES] set modules to reset data",
}

/**
 * Action to set data page
 */
export const tagSetModules = createAction(
  TypeModulesActions.modulesString,
  (modules: IModules) => ({ modules }),
);
