import { IPage } from "@app/models/pages";

export const singUpPagesConst: IPage[] = [
  {
    active: "initial",
    previous: "initial",
    next: "authorizations",
    list: [
      {
        name: "terms-and-conditions",
        params: {},
      },
      {
        name: "habeas-data",
        params: {},
      },
    ],
  },
  {
    active: "address",
    previous: "initial",
    next: "createpassword",
  },
  {
    active: "createpassword",
    previous: "authorizations",
    next: "otpcode",
  },
  {
    active: "otpcode",
    previous: "createpassword",
    next: "successful",
  },
  {
    active: "successful",
    previous: "otpcode",
    next: "",
  },
];
export const vehiclesPagesConst: IPage[] = [
  {
    active: "create",
    previous: "create",
    next: "successful",
  },
  {
    active: "successful",
    previous: "create",
    next: "",
  },
];

export const tagPagesConst: IPage[] = [
  {
    active: "shipping-address",
    previous: "buy-tag",
    next: "purchase-detail",
  },
  {
    active: "purchase-detail",
    previous: "shipping-address",
    next: "purchase-detail",
  },
  {
    active: "transaction",
    previous: "",
    next: "",
  },
];

export const tagActivationPagesConst: IPage[] = [
  {
    active: "activation",
    previous: "activation",
    next: "successful-activation",
  },
  {
    active: "successful-activation",
    previous: "activation",
    next: "successful-activation",
  },
];

export const signInPagesConst: IPage[] = [
  {
    active: "forgot-password",
    previous: "forgot-password",
    next: "new-password",
  },
  {
    active: "new-password",
    previous: "forgot-password",
    next: "identity-verification",
  },
  {
    active: "identity-verification",
    previous: "forgot-password",
    next: "successful",
  },
  {
    active: "successful",
    previous: "identity-verification",
    next: "",
  },
];
