import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CoreModule } from "@core/core.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ModalModule } from "../modal/modal.module";
import { ExampleTagComponent } from "./example-tag/example-tag.component";
import { GouRedirectComponent } from "./gou-redirect/gou-redirect.component";
import { PaymentOptionsComponent } from "./payment-options.component";

/**
 * @param http
 */
export function childLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    "assets/i18n/vehicle/successful-add/",
    ".json",
  );
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CoreModule,
    ModalModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: childLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    }),
  ],
  exports: [PaymentOptionsComponent],
  declarations: [
    PaymentOptionsComponent,
    ExampleTagComponent,
    GouRedirectComponent,
  ],
})
export class PaymentOptionsModule {}
