import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { createVehicleRootRoute } from "@create-vehicle/create-vehicle-routing.module";
import { successfulRoute } from "@successful-vehicle-add/successful-vehicule-add-routing.module";
import { VehicleGuard } from "./guards/vehicle.guard";
import { listVehicleRoot } from "./modules/list/list-routing.module";

export const vehicleRootRoute = "vehicle";

const routes: Routes = [
  {
    path: "",
    redirectTo: createVehicleRootRoute,
    pathMatch: "full",
  },
  {
    path: createVehicleRootRoute,
    loadChildren: () =>
      import("./modules/create/create-vehicle.module").then(
        (m) => m.CreateVehicleModule,
      ),
    canActivate: [VehicleGuard],
  },
  {
    path: successfulRoute,
    loadChildren: () =>
      import("./modules/successful/successful-vehicule-add.module").then(
        (m) => m.SuccessfulVehiculeAddModule,
      ),
    canActivate: [VehicleGuard],
  },
  {
    path: listVehicleRoot,
    loadChildren: () =>
      import("./modules/list/list-vehicle.module").then((m) => m.ListModule),
  },
  {
    path: "**",
    redirectTo: createVehicleRootRoute,
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VehiculesRoutingModule {}
