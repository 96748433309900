import { createAction } from "@ngrx/store";
import {
  ITimerCode,
  IVinculateModel,
  IVinculationResponse,
} from "@otp-code/models/otp-code";

export const enum TypeActions {
  OtpLoad = "[OTP Timer] timer to loaded otp",
  attemptsEditNumberString = "[OTP Timer] Attempts edit number",
  otpCodeVinculationLoadString = "[OTP Vinculation]  Loading data",
  otpCodeVinculationSaveString = "[OTP Vinculation] Successful data loaded",
  otpCodeVinculationErrorString = "[OTP Vinculation] Save error response",
  otpCodeVinculationResetDataString = "[OTP Vinculation] Reset data",
}

export const OtpTimer = createAction(
  TypeActions.OtpLoad,
  (timer: ITimerCode) => ({ timer }),
);
export const SetAttemptsEditNumber = createAction(
  TypeActions.attemptsEditNumberString,
  (attemptsEditNumber: ITimerCode) => ({ attemptsEditNumber }),
);
/**
 * Action to detect event loading vinculation data
 */
export const otpCodeVinculationLoad = createAction(
  TypeActions.otpCodeVinculationLoadString,
  (data: IVinculateModel) => ({ data }),
);
/**
 * Action to detect event loaded vinculation data
 */
export const otpCodeVinculationSave = createAction(
  TypeActions.otpCodeVinculationSaveString,
  (data: IVinculationResponse) => ({ data }),
);
/**
 * Action to set data error
 */
export const otpCodeVinculationError = createAction(
  TypeActions.otpCodeVinculationErrorString,
  (error: any) => ({ error }),
);

export const resetOtpCodeVinculationData = createAction(
  TypeActions.otpCodeVinculationResetDataString,
);
