import { createAction } from "@ngrx/store";

import { ITemporalPassword } from "@singup/models/sing-up";

export const enum TypeActions {
  temporalPasswordString = "[Temporal Password] set temporal password",
}

export const TemporalPassword = createAction(
  TypeActions.temporalPasswordString,
  (data: ITemporalPassword) => ({ data }),
);
