import { Component, HostBinding, OnInit, OnDestroy } from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { TranslateService } from "@ngx-translate/core";
import { CommonsService } from "@shared/services/commons.service";
import { Languages } from "@store/actions/app.action";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "fp-signout",
  templateUrl: "./signout.component.html",
  styleUrls: ["./signout.component.scss"],
})
export class SignOutComponent implements OnInit, OnDestroy {
  @HostBinding("class") fpSignOut = "fp-signout";
  destroy$: Subject<boolean> = new Subject<boolean>();
  bodyText: string;
  /**
   * Constructor
   *
   * @param translate Instance to translateService
   * @param appFacade Instance to AppFacade
   * @param commonService
   */
  constructor(
    public translate: TranslateService,
    private appFacade: AppFacade,
    private commonService: CommonsService,
  ) {}
  /**
   * Init state to set translate file
   */
  ngOnInit(): void {
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
    this.commonService.logOut(false);
  }
  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * button message action
   */
  async handleSuccess(): Promise<any> {
    this.commonService.navigate("/signin/login");
  }

  /**
   * get data of language selector
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }
}
