import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TagFeatureName, TagModuleState } from "../state/tag.state";

export const TagReservationRootSelector =
  createFeatureSelector<TagModuleState>(TagFeatureName);

export const selectTagReservation = createSelector(
  TagReservationRootSelector,
  (state: TagModuleState) => state.tagReservationReducer,
);
