/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export const RADIO_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioButtonComponent),
  multi: true,
};

export declare type fArgEmptyReturn = (arg0: any) => any;
export declare type fEmptyReturn = () => any;

@Component({
  selector: "fp-radiobutton",
  templateUrl: "./radiobutton.component.html",
  styleUrls: ["./radiobutton.component.scss"],
  providers: [RADIO_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonComponent implements ControlValueAccessor {
  @HostBinding("class") fpRadiobuttonC = "fp-radiobutton-c";
  @Input() value: any;

  @Input() name: string;

  @Input() disabled: boolean;

  @Input() label: string;

  @Input() internalLabel: string;

  @Input() tabindex: number;

  @Input() inputId: string;

  @Input() ariaLabelledBy: string;

  @Input() style: any;

  @Input() styleClass: string;

  @Input() labelStyleClass: string;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @Output() onFocus: EventEmitter<any> = new EventEmitter();

  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  @ViewChild("rb") inputViewChild: ElementRef;
  @ViewChild("rb") inputViewChildd: ElementRef;
  public checked: boolean;

  public focused: boolean;

  constructor(private cd: ChangeDetectorRef) {}

  onModelChange: fArgEmptyReturn = () => {};

  onModelTouched: fEmptyReturn = () => {};

  handleClick(event, radioButton, focus) {
    event.preventDefault();
    if (this.disabled) {
      return;
    }

    this.select(event);

    if (focus) {
      radioButton.focus();
    }
  }

  select(event) {
    if (!this.disabled) {
      this.inputViewChild.nativeElement.checked = true;
      this.checked = true;
      this.onModelChange(this.value);
      this.onClick.emit(event);
    }
  }

  writeValue(value: any): void {
    this.checked = value === this.value;

    if (this.inputViewChild && this.inputViewChild.nativeElement) {
      this.inputViewChild.nativeElement.checked = this.checked;
    }
    this.cd.markForCheck();
  }

  registerOnChange(fn: fArgEmptyReturn): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: fEmptyReturn): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
  }

  onInputFocus(event: any): void {
    this.focused = true;
    this.onFocus.emit(event);
  }

  onInputBlur(event: any): void {
    this.focused = false;
    this.onModelTouched();
    this.onBlur.emit(event);
  }

  onChange(event: any): void {
    this.select(event);
  }

  focus() {
    this.inputViewChild.nativeElement.focus();
  }
}
