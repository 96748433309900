import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  OnDestroy,
} from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { ModalService } from "@app/modules/theme/components/modal/service/modal.service";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "@store/actions/app.action";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "fp-modal-in-process",
  templateUrl: "./modal-in-process.component.html",
})
export class ModalInProcessComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public translate: TranslateService,
    private appFacade: AppFacade,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
  }

  /**
   * Destroy subscribes
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  close(): void {
    this.modalService.close("fp-report-in-process", false);
  }

  /**
   * get data of language selector
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }
}
