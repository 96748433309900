import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { homeRootRoute } from "@home/home-routing.module";
import { signInRootRoute } from "@signin/signin-routing.module";
import { signUpRootRoute } from "@singup/singup-routing.module";
import { vehicleRootRoute } from "@vehicles/vehicles-routing.module";
import { ActiveSessionGuard } from "./guards/active-session.guard";
import { AuthGuard } from "./guards/auth.guard";
import { CancelAccountRootRoute } from "./modules/cancel-account/cancel-account-routing.module";
import { genericErrorRootRoute } from "./modules/generic-error/generic-error-routing.module";
import { movementsRootRoute } from "./modules/movements/movements-routing.module";
import { OtherOptionsRootRoute } from "./modules/other-options/other-options-routing.module";
import { signOutRootRoute } from "./modules/signout/signout.routing.module";
import { tagRootRoute } from "./modules/tag/tag-routing.module";
import { transactionsRootRoute } from "./modules/transactions/transactions-routing.module";

const routes: Routes = [
  {
    path: "",
    redirectTo: homeRootRoute,
    pathMatch: "full",
  },
  {
    path: signUpRootRoute,
    loadChildren: () =>
      import("./modules/singup/singup.module").then((m) => m.SingUpModule),
    canActivate: [ActiveSessionGuard],
  },
  {
    path: signInRootRoute,
    loadChildren: () =>
      import("./modules/signin/signin.module").then((m) => m.SignInModule),
    canActivate: [ActiveSessionGuard],
  },
  {
    path: homeRootRoute,
    loadChildren: () =>
      import("./modules/home/home.module").then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: genericErrorRootRoute,
    loadChildren: () =>
      import("./modules/generic-error/generic-error.module").then(
        (m) => m.GenericErrorModule,
      ),
  },
  {
    path: vehicleRootRoute,
    loadChildren: () =>
      import("./modules/vehicles/vehicles.module").then(
        (m) => m.VehiclesModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: movementsRootRoute,
    loadChildren: () =>
      import("./modules/movements/movements.module").then(
        (m) => m.MovementsModule,
      ),
  },
  {
    path: tagRootRoute,
    loadChildren: () =>
      import("./modules/tag/tag.module").then((m) => m.TagModule),
    canActivate: [AuthGuard],
  },
  {
    path: transactionsRootRoute,
    loadChildren: () =>
      import("./modules/transactions/transactions.module").then(
        (m) => m.TransactionsModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: signOutRootRoute,
    loadChildren: () =>
      import("./modules/signout/signout.module").then((m) => m.SignOutModule),
  },
  {
    path: OtherOptionsRootRoute,
    loadChildren: () =>
      import("./modules/other-options/other-options.module").then(
        (m) => m.OtherOptionsModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: CancelAccountRootRoute,
    loadChildren: () =>
      import("./modules/cancel-account/cancel-account.module").then(
        (m) => m.CancelAccountModule,
      ),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
      useHash: true,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
