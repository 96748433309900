import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { IAddress } from "@app/modules/tag/models/address";
import { environment } from "@environment";
import { Observable } from "rxjs";
/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private http: HttpClient) {}
  /**
   * getAccounts
   *
   * @param clientId clientId
   * @returns Observable o Promise with data
   */
  getAccounts(clientId: string): Observable<any> {
    return this.http.get(
      environment.api_key.publicPN.back_url +
        environment.endpoints.accounts.init +
        `?clientId=${clientId.split("-")[0]}`,
      {
        params: new CustomHttpParams("api-pn-public-token"),
      },
    );
  }

  getCustomerInfo(): Observable<any> {
    return this.http.get(
      environment.api_key.publicPJ.back_url +
        environment.endpoints.customer_consultation.init,
      {
        params: new CustomHttpParams("api-pn-public-token"),
      },
    );
  }

  getUserInfo(nickname: string): Observable<any> {
    return this.http.get(
      environment.api_key.publicPJ.back_url +
        environment.endpoints.user_customer.init +
        `?companyId=${nickname.split("-")[0]}&userId=${nickname.split("-")[1]}`,
      {
        params: new CustomHttpParams("api-pn-public-token"),
      },
    );
  }

  getAddress(personId?: string) {
    return this.http.get<IAddress>(
      environment.api_key.publicPN.back_url +
        environment.endpoints.users.init +
        environment.endpoints.users.specific.addresses +
        `${personId}`,
      {
        params: new CustomHttpParams("api-toll-public-token"),
      },
    );
  }
}
