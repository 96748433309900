<fp-card-body [template]="'secondTemplate'" exit="true" [typeDashboard]="true" [actionType]="'logout'"
    [backOption]="true" [menuShow]="true" urlBack="/home">
    <ng-template>
        <div class="fp-list-container fp-fadeIn" *ngIf="!loading && vehicles">
            <h1 class="fp-h1 fp-list-title">{{ 'title' | translate }}</h1>
            <h2 class="fp-body-one fp-list-subtitle">{{ 'message' | translate }}</h2>
            <div class="fp-list-card">
                <div class="fp-list-header">
                    <div class="fp-list-header-filter fp-ml">
                        {{ 'vehicle' | translate }}
                        <div class="fp-list-card-icon" [class.disabled]="vehicles.length <= 1">
                            <img class="" src="assets/images/vehicle/filter.svg" alt="ordenar">
                        </div>
                    </div>
                    <div class="fp-list-flex">
                        <div class="fp-list-header-filter fp-mr">
                            {{ 'state' | translate }}
                            <div class="fp-list-card-icon" [class.disabled]="vehicles.length <= 1">
                                <img class="" src="assets/images/vehicle/filter.svg" alt="ordenar">
                            </div>
                        </div>
                        <div class="fp-list-header-filter fp-mr-big">
                            {{ 'action' | translate }}
                            <div class="fp-list-card-icon" [class.disabled]="vehicles.length <= 1">
                                <img class="" src="assets/images/vehicle/filter.svg" alt="ordenar">
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="vehicles.length !== 0">
                    <div class="status-line small">
                        <hr>
                    </div>
                    <div class="list-vehicles-container">
                        <ng-scrollbar class="fp-scrollbar" track="all" pointerEventsMethod="scrollbar">
                            <fp-item-list-vehicle *ngFor="let vehicle of vehicles; let indexEven = even"
                                [vehicle]="vehicle" (itemClickActive)="changeStatusTag($event)"
                                (vehicleInformation)="goTobuyTag($event)" [resetData]="error" [indexEven]="indexEven"
                                (openModalManagement)="openModalManagement($event,vehicle)"
                                (openModalDelete)="deleteVehicleShowModal($event)">
                            </fp-item-list-vehicle>
                        </ng-scrollbar>
                        <div class="items-empty-vehicle" *ngIf="vehicles.length === 0">
                            <div class="status-line">
                                <hr>
                            </div>
                            <fp-item-list-empty iconEmpety="assets/images/vehicle/vehicle-empty.svg"
                                [descriptionEmpety]="'list_empty' | translate"></fp-item-list-empty>
                            <div style="margin-top: 27px;"></div>
                        </div>
                    </div>
                </ng-container>
                <div class="status-line" *ngIf="vehicles.length !== 0">
                    <hr>
                </div>
                <button class="fp-btn-primary" type="submit" id="btn-list-create-vehicle"
                    (click)="navigatetoVehicleCreation()">
                    {{ 'create' | translate }}
                </button>
            </div>
        </div>
        <div class="fp-list-container" *ngIf="loading">
            <h1 class="fp-h1 fp-list-title">
                <div class="skeleton title-fake"></div>
            </h1>
            <h2 class="fp-body-one fp-list-subtitle">
                <div class="skeleton subtitle-fake"></div>
            </h2>
            <div class=" fp-list-card card-fake">
                <div class="row my-0">
                    <div class="skeleton filter-fake"></div>
                    <div class="skeleton filter-fake"></div>
                </div>
                <div *ngFor="let item of [1,2,3]" class="row">
                    <div class="skeleton circle">
                    </div>
                    <div class="colum mr-auto">
                        <div class="skeleton texts">
                        </div>
                        <div class="skeleton texts small">
                        </div>
                    </div>
                    <div class="skeleton switch-fake"></div>
                </div>
                <div class="skeleton button-fake">
                </div>
            </div>
        </div>
        <div *ngIf="!loading && !vehicles" class="notify-error">
            <fp-item-list-empty iconEmpety="assets/images/common/errorService.svg"
                [descriptionEmpety]=" 'error.message' | translate " [notifyError]="'true'"></fp-item-list-empty>
            <div style="margin-top: 27px;"></div>
            <div class="notify-error-btn">
                <button class="fp-btn-primary" type="submit" id="btn-list-create-vehicle" (click)="getVehicles(true)">
                    {{ 'error.btn_agin' | translate }}
                </button>
            </div>
        </div>
    </ng-template>
</fp-card-body>

<fp-delete-vehicle [vehicleInfo]="vehicleInfo" [category]="category" (itemClickActionDelete)="deleteVehicleClose($event)"></fp-delete-vehicle>

<fp-activation-progress (itemClickActive)=" navigatetoRecharge()" [titleModal]="titleModal"
    [labelBtnAccept]="labelBtnAccept" [hideClose]="hideClose">
</fp-activation-progress>

<!-- Aqui -->
<fp-vehicles-management (emitCloseModal)="closeModal('fp-vehicles-management')" [vehicleInfo]="itemVehicleSelected"
    (emitChangeStatus)="changeStatusTag($event)" [loading]="loadingChange" (openModalDelete)="deleteVehicleShowModal($event)">
</fp-vehicles-management>

<ng-template #alertActive>
    <fp-snack-message (snackMessageEvent)="closeAlertActive()" [title]="'alert.title' | translate "
        [description]="'alert.message' | translate" type="warning">
    </fp-snack-message>
</ng-template>

<ng-template #tempError>
    <fp-snack-message (snackMessageEvent)="closeAlertActive()" [title]="'Error' "
        [description]="messageError | translate " type="error">
    </fp-snack-message>
</ng-template>