import { Component } from "@angular/core";
import { ModalService } from "@theme/components/modal/service/modal.service";

@Component({
  selector: "fp-example-tag",
  templateUrl: "./example-tag.component.html",
  styleUrls: ["./example-tag.component.scss"],
})
export class ExampleTagComponent {
  constructor(private modalService: ModalService) {}

  /**
   * Close modal
   *
   * @param id string id elemnt Html
   */
  closeModal(id: string) {
    this.modalService.close(id, false);
  }
  modalAction(action: { typeAction: string; payload?: string }) {
    if (action.typeAction === "accept") {
      this.closeModal("fp-modal-example-tag");
    }
  }
}
