import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "@store/actions/app.action";
import { IVehicleInformation } from "@vehicles/models/vehicle";
import { VehiclesFacade } from "@vehicles/store/facade/vehicles.facade";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "fp-app-successful-vehicule-add",
  templateUrl: "./successful-vehicule-add.component.html",
  styleUrls: ["./successful-vehicule-add.component.scss"],
})
export class SuccessfulVehiculeAddComponent implements OnInit {
  @HostBinding("class") fpAppSuccessfulVehiculeAdd =
    "fp-app-successful-vehicule-add";
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    public translate: TranslateService,
    private appFacade: AppFacade,
    private vehicleFacade: VehiclesFacade,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
  }

  /**
   * actionClose
   *
   * @param $event boolean event
   */
  actionClose($event: boolean): void {
    this.selectVehiclesInformation$.subscribe((vehicleInformation) => {
      if (vehicleInformation.licensePlate) {
        const navigationExtras: NavigationExtras = {
          state: {
            ...vehicleInformation,
          },
        };
        this.router.navigate(["/signup/initial"], navigationExtras);
      }
    });
  }

  /**
   * get data of language selector
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  /**
   * get data of vehicle Information Selector
   */
  get selectVehiclesInformation$(): Observable<IVehicleInformation> {
    return this.vehicleFacade.selectVehiclesInformation$;
  }
}
