import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IModules } from "@app/models/modules";
import { IPages } from "@app/models/pages";
import { CommonsService } from "@shared/services/commons.service";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { SingupNavigationService } from "../services/singup-navigation.service";
import { SingUpFacade } from "../store/facade/singup.facade";

/**
 * Injectable to service
 */

@Injectable({
  providedIn: "root",
})
export class SingupGuard implements CanActivate {
  constructor(
    private singUpFacade: SingUpFacade,
    private commonsService: CommonsService,
    private appFacade: AppFacade,
    private signUpNavigationService: SingupNavigationService,
  ) {}
  /**
   * CanActivate
   *
   * @param route ActivatedRouteSnapshot
   * @param state State route of path to try navigate
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    await this.clearData();
    const info = await this.selectSingUp$.pipe(take(1)).toPromise();
    const validate = await this.commonsService.verifyPagesSignProcess(
      info,
      state.url.split("?")[0],
      "signup",
    );
    return validate;
  }
  /**
   * Clear Vehicle Data
   */
  async clearData(): Promise<any> {
    const modules: IModules = JSON.parse(
      JSON.stringify(await this.selectModules$.pipe(take(1)).toPromise()),
    );
    if (modules.signup === true) {
      modules.signup = false;
      this.signUpNavigationService.clearStore();
      this.appFacade.setModules(modules);
    }
  }

  /**
   * Get data to facade store communication
   */
  get selectSingUp$(): Observable<IPages> {
    return this.singUpFacade.selectSingUp$;
  }
  /**
   * Get Modules Information
   */
  get selectModules$(): Observable<IModules> {
    return this.appFacade.selectModules$;
  }
}
