import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ICities } from "@shared/models/cities";
import { citiesFeatureName } from "../state/cities.state";

export const CitiesRootSelector =
  createFeatureSelector<ICities>(citiesFeatureName);

export const selectCities = createSelector(
  CitiesRootSelector,
  (state: ICities) => state,
);
