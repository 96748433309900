import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { IUser } from "@app/models/user";
import { AuthenticationService } from "@singup/services/authentication.service";
import { from, Observable } from "rxjs";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class ActiveSessionGuard implements CanActivate {
  /**
   * Constructor
   *
   * @param router Router,
   * @param jwtHelperService JwtHelperService,
   * @param authenticationService AuthenticationService,
   */
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}
  /**
   * Method to validate route active
   */
  canActivate(): Observable<boolean> {
    return from(this.verifyToken());
  }
  /**
   * Verify Token existing with expiration
   */
  async verifyToken(): Promise<any> {
    let value = false;
    let token = "";
    let userCognito: IUser;

    userCognito =
      await this.authenticationService.getCurrentAuthenticatedUser();

    if (userCognito) {
      token = userCognito.data.authenticationResult.idToken;
    }
    if (
      !token ||
      token === "" ||
      this.authenticationService.tokenExpired(token)
    ) {
      value = true;
    } else {
      this.router.navigate(["/home"]);
    }

    return Promise.resolve(value);
  }
}
