import {
  Component,
  HostListener,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { pageWhitMenu } from "@core/constants/pages-menu.constant";
import { environment } from "@environment";
import { TranslateService } from "@ngx-translate/core";
import { BlockScrollService } from "@shared/services/block-scroll/block-scroll.service";
import { AuthenticationService } from "@singup/services/authentication.service";
import { ConnectionService } from "ng-connection-service";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { pageViewEvent } from "./helpers/utag.helpers";
import { ILoader, ILoaderState } from "./models/loader";
import { IUser } from "./models/user";

@Component({
  selector: "fp-root",
  styleUrls: ["./app.component.scss"],
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  load: string;
  param = null;
  userToken: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading: boolean = true;
  showMenu: boolean = false;
  loader: ILoader = {
    title: "",
    description: "",
  };
  timeoutId: any;
  userInactive: Subject<any> = new Subject();
  inactivityTime = environment.inactivityTime;
  @ViewChild("internetConnection", { static: true })
  internetConnection: TemplateRef<any>;
  timeToBlockScroll;

  /**
   * Constructor
   *
   * @param translate Translate Service
   * @param router Service to router
   * @param appFacade Principal Facade
   * @param blockScrollService
   * @param authenticationService
   * @param recaptchaV3Service
   * @param connectionService
   * @param snackBar
   * @param elementRef
   */
  constructor(
    public translate: TranslateService,
    private router: Router,
    private appFacade: AppFacade,
    private blockScrollService: BlockScrollService,
    private authenticationService: AuthenticationService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private connectionService: ConnectionService,
    private snackBar: MatSnackBar,
    private elementRef: ElementRef,
  ) {
    this.checkInternetConnection();
  }
  /**
   * Check Time out with Active current session
   */
  checkTimeOut(): void {
    this.timeoutId = setTimeout(() => {
      this.handleTimeout();
    }, this.inactivityTime);
  }

  async handleTimeout(): Promise<void> {
    let userCognito: IUser;
    userCognito =
      await this.authenticationService.getCurrentAuthenticatedUser();
    if (userCognito) {
      this.userInactive.next();
    }
  }
  /**
   * Initialize data
   */
  ngOnInit(): void {
    this.elementRef.nativeElement.removeAttribute("ng-version");
    this.checkTimeOut();
    this.userInactive.subscribe(() => {
      this.router.navigate(["/signout"]);
    });
    this.registerRouteChanges();
    this.selectLoader$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.loading = value.loading;
      if (value.loading) {
        document.body.classList.add("loader-active");
      } else {
        document.body.classList.remove("loader-active");
      }
      switch (value.type) {
        case "GENERAL":
          this.loader.title = "loader.general.title";
          this.loader.description = "loader.general.description";
          break;
        case "LOGIN":
          this.loader.title = "loader.login.title";
          this.loader.description = "loader.login.description";
          break;
      }
    });
  }
  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  /**
   * Change language observable
   *
   * @param language
   */
  changeLanguage(language: string): void {
    this.translate.use(language);
  }
  /**
   * get Token from Google
   *
   * @param attemps intentos
   */
  async getToken(attemps: number = 1): Promise<any> {
    let token = sessionStorage.getItem("captcha");
    if (attemps < 5) {
      try {
        token = await this.recaptchaV3Service
          .execute("importantAction")
          .toPromise();
        sessionStorage.setItem("captcha", token);
      } catch (error) {
        await this.getToken(attemps + 1);
      }
    }
  }
  /**
   * Change observable router's
   */
  registerRouteChanges(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        const path = event.url.split("?")[0];
        if (
          path === "/signin/login" ||
          path === "/signup/initial" ||
          path === "/signin" ||
          path === "/signup"
        ) {
          this.getToken();
        }
        const aTags = document.getElementsByTagName("div");
        const searchText =
          "Could not connect to the reCAPTCHA service. Please check your internet connection and reload to get a reCAPTCHA challenge.";
        let found: HTMLElement;
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < aTags.length; i++) {
          if (aTags[i].textContent === searchText) {
            found = aTags[i];
            found.style.display = "none";
            break;
          }
        }

        pageViewEvent(path);
        pageWhitMenu.find((p) => p.route === event.url)
          ? (this.showMenu = true)
          : (this.showMenu = false);
      }
    });
  }

  /**
   * get selector selectTagTermsandConditions on Facade
   */
  get selectLoader$(): Observable<ILoaderState> {
    return this.appFacade.selectLoader$;
  }
  /**
   * Activate scroll
   *
   * @param e event
   */
  onActivate(e: any): void {
    if (e.elementForScroll !== undefined) {
      this.blockScrollService.blockScroll();
    }
    this.timeToBlockScroll = setTimeout(() => {
      if (e.elementForScroll) {
        this.blockScrollService.calcWindow(e.elementForScroll);
      }
    }, 1500);
  }
  /**
   * Deaactivate scroll
   */
  onDeactivate(): void {
    clearTimeout(this.timeToBlockScroll);
    this.blockScrollService.enableScroll();
  }
  @HostListener("window:keydown")
  @HostListener("window:mousedown")
  @HostListener("window:mouseover")
  @HostListener("touchstart", ["$event"])
  @HostListener("touchend", ["$event"])
  /**
   * verify User Activity
   */
  checkUserActivity(): void {
    clearTimeout(this.timeoutId);
    this.checkTimeOut();
  }
  @HostListener("window:keydown")
  @HostListener("click", ["$event.target"])
  async checkRefreshToken() {
    let userCognito: IUser;
    userCognito =
      await this.authenticationService.getCurrentAuthenticatedUser();
    if (userCognito) {
      const time = await this.authenticationService.calculateTimeExpiration();
      if (time < 60000 && time > 100) {
        await this.authenticationService.refreshToken();
      }
      if (time <= 100) {
        this.router.navigate(["/signout"]);
      }
    }
  }

  /**
   * check Internet Connection to show an notification
   */
  checkInternetConnection(): void {
    this.connectionService.monitor().subscribe((isConnected) => {
      if (!isConnected) {
        this.snackBar.openFromTemplate(this.internetConnection, {
          horizontalPosition: "center",
          verticalPosition: "top",
        });
      } else {
        this.snackBar.dismiss();
      }
    });
  }
}
