<div [ngClass]="{
    'container-snack-message-error':type === 'error',
    'container-snack-message-info':type === 'info',
    'container-snack-message-warning':type === 'warning',
    'container-snack-message-info-only-description':type === 'infoOnlyDescription' }">

    <img [ngClass]="{
            'icon-snack-message-error':type === 'error',
            'icon-snack-message-info':type === 'info' || type === 'infoOnlyDescription',
            'icon-snack-message-warning':type === 'warning' }" [src]="type === 'info' || type === 'infoOnlyDescription' ?  'assets/images/common/icon-info.svg' : (type === 'warning'
        ? 'assets/images/register/icon-war.svg' : (type==='error' ) ? 'assets/images/common/icon-error.svg' : '' )"
        alt="{{type}}">

    <div class="container-snack-message-title">

        <div *ngIf="title" [ngClass]="{
            'title-snack-message-error':type === 'error',
            'title-snack-message-info':type === 'info' || type === 'infoOnlyDescription',
            'title-snack-message-warning':type === 'warning' }">
            {{ title }}
        </div>

        <div [ngClass]="{
            'description-snack-message-error':type === 'error',
            'description-snack-message-info':type === 'info' || type === 'infoOnlyDescription',
            'description-snack-message-warning':type === 'warning' }">
            {{ description }}
        </div>

    </div>

    <img class="icon-snack-message-back" id="icon-snack-message-back" [src]="type === 'info'  || type === 'infoOnlyDescription' ?  'assets/images/common/icon-back.svg' : 
        (type === 'warning' ? 'assets/images/common/icon-back-warning.svg' : 
        (type === 'error') ? 'assets/images/common/icon-back-error.svg' : '' )" alt="Cerrar" (click)="closeMessage()">

</div>