import { createAction, props } from "@ngrx/store";

export const enum TypeActionsStatusTransactions {
  statusTransactionLoad = "[Transactions Component] Loading data",
  statusTransactionSave = "[Transactions Component] Succesfull data loaded",
  statusTransactionsError = "[Transactions Component] Save error response",
  statusTransactionReset = "[Transactions Component] Reset data",
}
/**
 * Action to service events
 */

export const statusTransactionsLoad = createAction(
  TypeActionsStatusTransactions.statusTransactionLoad,
  props<{ url?; body? }>(),
);
export const statusTransactionSuccess = createAction(
  TypeActionsStatusTransactions.statusTransactionSave,
  props<{ status }>(),
);
export const statusTransactionError = createAction(
  TypeActionsStatusTransactions.statusTransactionsError,
  props<{ error }>(),
);
export const statusTransactionsReset = createAction(
  TypeActionsStatusTransactions.statusTransactionReset,
  props<{ initialData }>(),
);
