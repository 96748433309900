<div class="container-content">
    <div class="content-menu">
        <nav class="fp-nav-primary">
            <ul class="fp-list-menu">
                <img class="fp-logo-blanck" src="assets/images/nav-menu/logoFp.svg" alt="Icono">
                <li class="fp-list-item" [routerLink]="itemMenu.router" routerLinkActive="active"
                    *ngFor="let itemMenu of itemsMenu">
                    <img class="fp-icon-menu" [src]="itemMenu.icon" alt="Icono">
                    <a>{{itemMenu.title}}</a>
                </li>
            </ul>
        </nav>
    </div>
</div>