import { IModules } from "@app/models/modules";

export const modulesFeatureName = "modulesReducer";

export const initialModulesState: IModules = {
  tag: false,
  transaction: false,
  vehicle: false,
  signin: false,
  signup: false,
};

export const initialModulesResetState: IModules = {
  tag: true,
  transaction: true,
  vehicle: true,
  signin: true,
  signup: true,
};
