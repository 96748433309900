import { createReducer, on } from "@ngrx/store";
import * as identificationTypesActions from "../actions/identification-types.action";
import { IIdentifiTypesState } from "../state/app.state";

const initialState: IIdentifiTypesState = {
  documenTypes: null,
  error: null,
  loaded: null,
  loading: null,
};

export const identificationTypesReducer = createReducer(
  initialState,
  on(identificationTypesActions.documentTypeLoad, (state) => ({
    ...state,
    loading: true,
    documenTypes: null,
    error: null,
    loaded: null,
  })),
  on(
    identificationTypesActions.documentTypeSuccess,
    (state, { documenTypes }) => ({
      ...state,
      documenTypes,
      error: null,
      loaded: true,
      loading: false,
    }),
  ),
  on(identificationTypesActions.documentTypeError, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false,
  })),
);
