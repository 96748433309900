import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  OnDestroy,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AppFacade } from "@app/app.facade";
import { IAccount } from "@app/models/accounts";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "@store/actions/app.action";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "fp-popup-avalpay",
  templateUrl: "./popup-avalpay.component.html",
})
export class PopupAvalPayComponent implements OnInit, OnDestroy {
  @Output() itemClickActive = new EventEmitter<{}>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  formEditInfo = new UntypedFormGroup({
    generic: new UntypedFormControl("", [
      Validators.required,
      Validators.pattern("^[3]{1}[0-9]{9}$"),
    ]),
  });

  nure: string;

  constructor(
    public translate: TranslateService,
    private appFacade: AppFacade,
  ) {}

  ngOnInit(): void {
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
    this.selectAccounts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IAccount) => {
        this.nure = action.data[0].nure;
        this.formEditInfo.controls["generic"].setValue(this.nure);
      });
  }

  /**
   * Destroy subscribes
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  /**
   * modal action
   *
   * @param $event event
   */
  actionModal() {
    this.itemClickActive.emit();
  }

  copiarTexto(): void {
    navigator.clipboard.writeText(this.nure);
  }

  /**
   * get data of language selector
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }
}
