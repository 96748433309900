import { Directive, ElementRef, Input, OnInit } from "@angular/core";
/**
 * Directive
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[fpAutoFocus]",
})
export class AutofocusDirective implements OnInit {
  private autoFocus: boolean;
  /**
   * Constructor
   *
   * @param el ElementRef
   */
  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (this.autoFocus) {
      this.el.nativeElement.focus();
    }
  }
  /**
   * set autoFocus condition
   */
  @Input() set fpAutoFocus(condition: boolean) {
    this.autoFocus = condition !== false;
  }
}
