import { createReducer, on } from "@ngrx/store";
import * as appActions from "../actions/app.action";
/**
 * Model to language Data
 */
/**
 * Model to
 */
export interface ILanguage {
  language: appActions.Languages;
}

const initialState: ILanguage = {
  language: "es",
};

export const appReducer = createReducer(
  initialState,
  on(appActions.changeLanguage, (state, { language }) => ({
    ...state,
    language,
  })),
);
