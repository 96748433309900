import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { OtherOptionsComponent } from "./other-options.component";

export const OtherOptionsRootRoute = "options";
const routes: Routes = [
  {
    component: OtherOptionsComponent,
    path: "",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OtherOptionsRoutingModule {}
