import { Component, HostBinding, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { pageEvent } from "@app/helpers/utag.helpers";
import { IUserInfo } from "@app/models/user-info";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "@shared/services/storage.service";
import { InputComponent } from "@theme/components/input/input.component";
import {
  IVehicleCreationValidationMessages,
  IVehicleInformation,
  IVehiclesCategories,
  IVehiclesCategoriesResponse,
  IVehiculeCreation,
} from "@vehicles/models/vehicle";
import { VehicleNavigationService } from "@vehicles/services/vehicle-navigation.service";
import { VehiclesService } from "@vehicles/services/vehicle.service";
import { VehiclesFacade } from "@vehicles/store/facade/vehicles.facade";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";

@Component({
  selector: "fp-create-vehicle",
  templateUrl: "./create-vehicle.component.html",
  styleUrls: ["./create-vehicle.component.scss"],
})
export class CreateVehiculeComponent implements OnInit {
  @HostBinding("class") fpCreateVehicle = "fp-create-vehicle";
  @ViewChild("licensePlate") inputLicensePlate: InputComponent;
  showSpam: boolean = true;
  formCreateVehicle: UntypedFormGroup;
  createVehicleValidationMessages: IVehicleCreationValidationMessages;
  vehicleTypes: IVehiclesCategoriesResponse[];

  constructor(
    private vehicleFacade: VehiclesFacade,
    public translate: TranslateService,
    private vehicleService: VehiclesService,
    private vehicleNavigationService: VehicleNavigationService,
    private router: Router,
    private appFacade: AppFacade,
    private storageService: StorageService,
  ) {}
  /**
   * initial LifeCycle
   */
  async ngOnInit(): Promise<any> {
    this.initForm();
    await this.getInitialState();
    this.selectVehiclesInformation$
      .pipe(take(1))
      .subscribe((vehicleInformation) => {
        if (vehicleInformation.licensePlate || vehicleInformation.vehicleType) {
          this.formCreateVehicle.setValue({
            licensePlate: vehicleInformation.licensePlate.toUpperCase(),
            vehicleType: vehicleInformation.vehicleType,
          });
          this.formCreateVehicle.get("licensePlate").markAsTouched();
        }
      });
  }
  /**
   * get Initial State
   */
  async getInitialState(): Promise<any> {
    const promisevehiclesCategories = await this.selectVehicle$
      .pipe(take(1))
      .toPromise();
    if (!promisevehiclesCategories || promisevehiclesCategories.data === null) {
      this.vehicleFacade.getVehiclesCategories();
      this.selectVehicle2$
        .pipe(take(2))
        .subscribe((action: IVehiclesCategories) => {
          if (action && action.data !== null) {
            this.vehicleTypes = action.data;
            this.vehicleNavigationService.next(0);
          }
          if (action && action.error !== null) {
            this.vehicleNavigationService.navigatetoError();
          }
        });
    }
    if (promisevehiclesCategories && promisevehiclesCategories.data !== null) {
      this.vehicleTypes = promisevehiclesCategories.data;
    }
  }
  /**
   * close Info Spam
   */
  closeInfoSpam(): void {
    this.showSpam = false;
  }
  /**
   * Init Form
   */
  initForm(): void {
    this.formCreateVehicle = new UntypedFormGroup({
      licensePlate: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9]{4,10}$"),
      ]),
      vehicleType: new UntypedFormControl("", [Validators.required]),
    });
    this.createVehicleValidationMessages = {
      licensePlate: [
        {
          type: "required",
          message: "form_errors.licensePlate.required",
        },
        {
          type: "pattern",
          message: "form_errors.licensePlate.pattern",
        },
        {
          type: "exists",
          message: "form_errors.licensePlate.exists",
        },
        {
          type: "other",
          message: "form_errors.licensePlate.other",
        },
      ],
      vehicleType: [
        { type: "required", message: "form_errors.vehicleType.required" },
        { type: "pattern", message: "form_errors.vehicleType.pattern" },
      ],
    };
  }

  /**
   * Select the first document obtained in the service
   *
   * @param vehicleType Car Colombian Vehicle Type
   */
  chooseVehicleType(vehicleType: string): void {
    this.setVehicleInformation({
      licensePlate: this.formCreateVehicle.controls.licensePlate.value,
      vehicleType,
    });
    this.formCreateVehicle.controls.vehicleType.setValue(vehicleType);
  }
  /**
   * Set Vehicle Information on state
   *
   * @param vehicleInformation vehicleInformation
   */
  setVehicleInformation(vehicleInformation: IVehicleInformation): void {
    this.vehicleFacade.setVehicleInformation(vehicleInformation);
  }
  /**
   * change value of vehicle type
   *
   * @param val value
   */
  handleChange(val: string): void {
    this.setVehicleInformation({
      vehicleType: this.formCreateVehicle.controls.vehicleType.value,
      licensePlate: val,
    });
  }

  /**
   * get userInfo
   */
  get selectUserInfo$(): Observable<IUserInfo> {
    return this.appFacade.selectUserInfo$;
  }

  async validateSelectUserInfo$() {
    let userInfoPromise;
    if (this.selectUserInfo$ !== undefined) {
      userInfoPromise = await this.selectUserInfo$.pipe(take(1)).toPromise();
    }
    return userInfoPromise;
  }

  /**
   * create Vehicle
   */
  async createVehicle(): Promise<any> {
    if (this.formCreateVehicle.invalid) {
      return;
    }
    this.setVehicleInformation({
      vehicleType: this.formCreateVehicle.controls.vehicleType.value,
      licensePlate:
        this.formCreateVehicle.controls.licensePlate.value.toUpperCase(),
    });
    const accountId = await this.storageService.getItem("accountId");
    const servicesUser = await this.validateSelectUserInfo$();
    const services =
      servicesUser.data.services !== undefined
        ? servicesUser.data.services
        : [];
    const data: IVehiculeCreation = {
      vehicleToCreate: {
        licensePlate: this.formCreateVehicle.value.licensePlate.toUpperCase(),
        state: "1",
        category: this.formCreateVehicle.value.vehicleType,
        account: accountId,
        services: services,
      },
    };
    try {
      this.appFacade.setLoaderShow({
        type: "GENERAL",
      });
      await this.vehicleService.postVehicle(data).toPromise();
      this.vehicleNavigationService.next(1);
    } catch (error) {
      if (error.error.data) {
        pageEvent(
          error.error.data.message,
          "/vehicle/create",
          "Placa ya registrada",
        );
        this.setErrorInput(error.error.data.code);
      } else {
        this.vehicleNavigationService.navigatetoError();
      }
    } finally {
      this.appFacade.setLoaderHide();
    }
  }

  /**
   * Set errot to Input in form
   *
   * @param setErrot boolean error
   */
  setErrorInput(setError: string): void {
    if (setError) {
      if (setError === "100134" || setError === "100050")
        this.formCreateVehicle.get("licensePlate").setErrors({ exists: true });

      if (setError === "100722")
        this.formCreateVehicle.get("licensePlate").setErrors({ other: true });

      this.formCreateVehicle.get("licensePlate").markAsTouched();
    } else {
      this.formCreateVehicle.get("licensePlate").updateValueAndValidity();
    }
    this.inputLicensePlate.updateInput();
  }

  /**
   * action to Close
   *
   * @param $event event
   */
  actionClose($event: boolean): void {
    this.router.navigate(["/signup/initial"]);
  }

  get selectVehicle$(): Observable<IVehiclesCategories> {
    return this.vehicleFacade.selectVehiclesCategories$;
  }

  get selectVehicle2$(): Observable<IVehiclesCategories> {
    return this.vehicleFacade.selectVehiclesCategories2$;
  }

  /**
   * get data of vehicle Information Selector
   */
  get selectVehiclesInformation$(): Observable<IVehicleInformation> {
    return this.vehicleFacade.selectVehiclesInformation$;
  }
}
