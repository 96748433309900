import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { ITagFreeRequest } from "../../models/transactionsStatus.interface";
import {
  statusTransactionsLoad,
  statusTransactionsReset,
} from "../actions/statusTransaction.actions";
import { selectStatusTransaction } from "../selectors/statusTransaction.selector";
import {
  IStatusTransactionState,
  TransactionsModuleState,
} from "../state/statusTransaction.state";

@Injectable()
export class StatusTransactionFacade {
  constructor(private store: Store<TransactionsModuleState>) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectStatusTransaction$: Observable<IStatusTransactionState> =
    this.store.pipe(select(selectStatusTransaction));

  /**
   * get pse status transaction data
   *
   * @param data
   * @param data.url
   * @param data.body
   */
  public getStatusTransaction(data: {
    url?: string;
    body?: ITagFreeRequest;
  }): void {
    this.store.dispatch(statusTransactionsLoad(data));
  }

  public resetStatusTransactionData(): void {
    const initialData: IStatusTransactionState = {
      status: null,
      loading: false,
      loaded: false,
      error: null,
    };
    this.store.dispatch(statusTransactionsReset({ initialData }));
  }
}
