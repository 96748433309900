import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "date",
})
export class DatePipe implements PipeTransform {
  transform(value: string, type: number): string {
    const arrayDate = value.split("-");
    let newDate;
    newDate = `${arrayDate[2]}/${this.shortMonth(arrayDate[1])}/${
      arrayDate[0]
    }`;
    return newDate;
  }

  shortMonth(value: string): string {
    let short = "Dic";
    switch (value) {
      case "01":
        short = "Ene";
        break;
      case "02":
        short = "Feb";
        break;
      case "03":
        short = "Mar";
        break;
      case "04":
        short = "Abr";
        break;
      case "05":
        short = "May";
        break;
      case "06":
        short = "Jun";
        break;
      case "07":
        short = "Jul";
        break;
      case "08":
        short = "Ago";
        break;
      case "09":
        short = "Sep";
        break;
      case "10":
        short = "Oct";
        break;
      case "11":
        short = "Nov";
        break;
      default:
        break;
    }
    return short;
  }
}
