import { IProspectsInvalid, IRegister } from "@register/models/register";

export const RegisterFeatureName = "RegisterModuleState";

export type RegisterModuleState = Readonly<{
  registerReducer: IRegister;
  prospectsInvalid: IProspectsInvalid;
}>;

export const initialStateRegister: IRegister = {
  identificationType: null,
  identificationNumber: null,
  name: null,
  lastname: null,
  email: null,
  phone: null,
  termsandConditions: null,
  personalData: null,
};

export const initialStateIProspectsInvalid: IProspectsInvalid = {
  identificationNumber: null,
  identificationNumberType: null,
  invalid: null,
  source: null,
  error: null,
  loaded: null,
  loading: null,
};
