import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { StatusComponent } from "../status/status.component";
import { RechargeComponent } from "./recharge.component";

export const createRechargeRootRoute = "recharge";

const routes: Routes = [
  {
    component: RechargeComponent,
    path: "",
  },

  {
    component: StatusComponent,
    path: "status",
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RechargeRoutingModule {}
