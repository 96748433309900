import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IVehicleInformation } from "@app/modules/vehicles/models/vehicle";
import { VehiclesFacade } from "@app/modules/vehicles/store/facade/vehicles.facade";
import { Languages } from "@app/store/actions/app.action";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ModalService } from "../modal/service/modal.service";

@Component({
  selector: "fp-payment-options",
  templateUrl: "./payment-options.component.html",
  styleUrls: ["./payment-options.component.scss"],
})
export class PaymentOptionsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() title: string;
  @Input() haveTag: boolean = false;
  @Input()
  messageSanitizer: SafeHtml;
  @Input() set infoMessage(text: string) {
    this.messageSanitizer = this.sanitizer.bypassSecurityTrustHtml(text);
  }

  plate: string;
  gouLink: string = "";
  publicLink: string = "";
  showBanner: boolean = false;

  constructor(
    private modalService: ModalService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private appFacade: AppFacade,
    private vehicleFacade: VehiclesFacade,
    public translate: TranslateService,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
    this.selectVehiclesInformation$.subscribe((vehicleInformation) => {
      if (vehicleInformation.licensePlate) {
        this.plate = vehicleInformation.licensePlate;
      }
    });
    this.http
      .get("assets/i18n/vehicle/successful-add/es.json")
      .subscribe((data: any) => {
        this.gouLink = data.modal_gou.link;
        this.publicLink = data.link;
        this.showBanner = data.show_banner;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  goToShippingTag($event?: string): void {
    if ($event) {
      this.router.navigate(["/tag/activation"]);
    } else {
      this.router.navigate(["/tag/shipping-address"]);
    }
  }

  navigateGou(): void {
    this.modalService.close("fp-gou-modal", true);
    window.open(this.gouLink, "_self");
  }

  navigatePublicPage(): void {
    this.modalService.close("fp-gou-modal", true);
    window.open(this.publicLink, "_blank");
  }

  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  /**
   * get data of vehicle Information Selector
   */
  get selectVehiclesInformation$(): Observable<IVehicleInformation> {
    return this.vehicleFacade.selectVehiclesInformation$;
  }
}
