import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  DocumentsFeatureName,
  DocumentsModuleState,
} from "../state/documents.state";

export const OtpCodeRootSelector =
  createFeatureSelector<DocumentsModuleState>(DocumentsFeatureName);

export const selectotpCode = createSelector(
  OtpCodeRootSelector,
  (state: DocumentsModuleState) => state.otpCodeReducer,
);
