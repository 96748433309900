<fp-card-body [template]="'secondTemplate'" close="true" backOption="true" urlBack="/movements/list">
    <ng-template>
        <div class="fp-reports-container">
            <div class="text-center">
                <h1 class="title fp-h1">{{ "title" | translate }}</h1>
                <h2 class="subtitle fp-body-one"> {{ "subtitle" | translate}}</h2>
            </div>

            <div class="fp-card-detail">
                <div class="datepicker-container">
                    <fp-datepicker [maxDate]="maxDateLimit" [minDate]="minDateLimit"
                        (selectedDate)="selectedDateHandler($event)"></fp-datepicker>
                </div>
                <button class="fp-btn-primary report-btn" type="submit" [disabled]="!this.dateRange" (click)="requestReport()" >
                    {{ 'btn_generate' | translate }}
                </button>
            </div>
            <div class="advice">
                <div [innerHtml]="adviceText"></div>
            </div>


            <div novalidate *ngIf="!loading && !isMobile" class="container-detail-vehicles fp-fadeIn">
                <div class="list-vehicles-container-filters space-vehicles-header">
                    <div class="vehicles-btn-filter subtitle-vehicle title">
                        Reporte
                        <button class="fp-btn-white fp-btn-filter"  type="button"
                            id="btn-order-license-plate">
                            <img src="assets/images/report/arrow-filter.svg" [class.rotar]="filters.licensePlate"
                                alt="icono organizar">
                        </button>
                    </div>
                    <div class="vehicles-btn-filter subtitle-vehicle description" >
                        Rango de fechas
                        <button class="fp-btn-white fp-btn-filter" type="button" id="btn-order-numberTag">
                            <img src="assets/images/report/arrow-filter.svg" [class.rotar]="filters.numberTag"
                                alt="icono organizar">
                        </button>
                    </div>
                    <div class="vehicles-btn-filter subtitle-vehicle format" >
                        Formato
                        <button class="fp-btn-white fp-btn-filter" type="button" id="btn-order-info-aditional">
                            <img src="assets/images/report/arrow-filter.svg" [class.rotar]="filters.infoAditional"
                                alt="icono organizar">
                        </button>
                    </div>
                    <div class="vehicles-btn-filter subtitle-vehicle status">
                        Estado
                    </div>
                    <div class="vehicles-btn-filter subtitle-vehicle action">
                        Acción
                    </div>
                </div>
                
                <div class="item-info big" *ngIf="!loading && reportsPaginator?.length !== 0">
                    <img src="assets/images/common/icon-info.svg" alt="info">
                    <span>
                        {{ 'info_message' | translate }}
                    </span>
                </div>
                
                <div class="list-vehicles-container">

                    <ng-container *ngIf="!loading && reportsState?.error">
                        <fp-item-list-empty iconEmpety="assets/images/common/errorService.svg"
                            [descriptionEmpety]="'Lo sentimos, tenemos un error al cargar su información'"
                            [notifyError]="'true'">
                        </fp-item-list-empty>
                        <button class="fp-btn-green btn-empty" type="button" id="btn-list-get-vehicles"
                            (click)="getReports()">
                            {{ 'try_again' | translate }}
                        </button>
                    </ng-container>

                    <ng-container *ngIf="!loading && reportsPaginator?.length === 0 && !reportsState?.error">
                        <fp-item-list-empty iconEmpety="assets/images/report/file.svg"
                            [descriptionEmpety]="'Aun no tiene reportes'"></fp-item-list-empty>                            
                    </ng-container>

                    
                    <ng-container *ngIf="!loading && reportsPaginator.length > 0">
                        <div class="fp-item-row j-center" *ngFor="let report of reportsPaginator; let indexEven = even">
                            <div class="fp-item-title" [ngClass]="{'error-width': report.status === 4}">
                                {{ 'movements' | translate }}
                            </div>
                            <div  class="fp-item-description" [ngClass]="{'error-width': report.status === 4}">
                                {{ report.startDate | date:report.reportType  }} a {{report.finalDate | date:report.reportType }}
                            </div>
                            <div class="fp-item-format">
                                {{ report.formatReport }}
                            </div>
                            <div class="fp-item-status" [ngClass]="{'error-width': report.status === 4}">
                                <ng-container *ngIf="report.status === 1 || report.status === 2 ">
                                    <div class="response inprocess" *ngIf="!loading && reportsPaginator.length !== 0">
                                        <img src="assets/images/report/inprocess-icon.svg" alt="info">
                                        <span>
                                            {{ 'in_process' | translate }}
                                        </span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="report.status === 3">
                                    <div class="response success" *ngIf="!loading && reportsPaginator.length !== 0">
                                        <img src="assets/images/report/success-icon.svg" alt="info">
                                        <span>
                                            {{ 'generated' | translate }}
                                        </span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="report.status === 4">
                                    <div class="response error" *ngIf="!loading && reportsPaginator.length !== 0">
                                        <img src="assets/images/report/error-icon.svg" alt="info">
                                        <span>
                                            {{ 'fail' | translate }}
                                        </span>
                                    </div>
                                        
                                    </ng-container>
                            </div>
                            <div class="fp-item-action" [ngClass]="{'error-width': report.status === 4}">
                                <ng-container *ngIf="report.status === 3">
                                    <button class="fp-btn-green btn-action" (click)="downloadReport(report.reportId)"
                                    [id]="'btn-download-report-' + report.reportId">
                                        <span class="btn-content-text" *ngIf="!loadingReport">
                                            {{ 'download' | translate }}
                                        </span>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div novalidate *ngIf="!loading && isMobile" class="container-detail-vehicles fp-fadeIn">
                <div class="item-info big" *ngIf="!loading && reportsPaginator.length !== 0">
                    <img src="assets/images/common/icon-info.svg" alt="info">
                    <span>
                        {{ 'info_message' | translate }}
                    </span>
                </div>
                
                <div class="list-vehicles-container">        
                    <ng-container *ngIf="!loading && reportsPaginator.length > 0">
                        <div class="fp-mb-item-row" *ngFor="let report of reportsPaginator; let indexEven = even">
                            <div class="fp-mb-item-column-1">
                                <div class="fp-mb-item-title">
                                    {{ 'movements' | translate }}
                                </div>
                                <div  class="fp-mb-item-description">
                                    {{ report.startDate | date:report.reportType  }} a {{report.finalDate | date:report.reportType }}
                                </div>
                                <div class="fp-mb-item-status" [ngClass]="{'error-width': report.status === 4}">
                                    <ng-container *ngIf="report.status === 1 || report.status === 2 ">
                                        <div class="response inprocess" *ngIf="!loading && reportsPaginator.length !== 0">
                                            <img src="assets/images/report/inprocess-icon.svg" alt="info">
                                            <span>
                                                {{ 'in_process' | translate }}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="report.status === 3">
                                        <div class="response success" *ngIf="!loading && reportsPaginator.length !== 0">
                                            <img src="assets/images/report/success-icon.svg" alt="info">
                                            <span>
                                                {{ 'generated' | translate }}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="report.status === 4">
                                        <div class="response error" *ngIf="!loading && reportsPaginator.length !== 0">
                                            <img src="assets/images/report/error-icon.svg" alt="info">
                                            <span>
                                                {{ 'fail' | translate }}
                                            </span>
                                        </div>
                                            
                                        </ng-container>
                                </div>
                            </div>
                            <div class="fp-mb-item-column-2">
                                <div class="fp-mb-item-action">
                                    <ng-container *ngIf="report.status === 3">
                                        <button class="fp-btn-green btn-action" (click)="downloadReport(report.reportId)"
                                        [id]="'btn-download-report-' + report.reportId">
                                            <span class="btn-content-text" *ngIf="!loadingReport || report.reportId !== report.reportId">
                                                {{ 'download' | translate }}
                                            </span>
                                            <span class="loader" *ngIf="loadingReport && report.reportId === report.reportId"></span>
                                        </button>
                                    </ng-container>
                                </div>
                            </div>
                            
                            
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!loading && reportsState?.error">
                        <fp-item-list-empty iconEmpety="assets/images/common/errorService.svg"
                            [descriptionEmpety]="'Lo sentimos, tenemos un error al cargar su información'"
                            [notifyError]="'true'">
                        </fp-item-list-empty>
                        <button class="fp-btn-green try-again btn-empty" type="button" id="btn-list-get-vehicles"
                            (click)="getReports()">
                            Volver a intentar
                        </button>
                    </ng-container>

                </div>
            </div>

        </div>
    </ng-template>
</fp-card-body>

<fp-modal-in-process></fp-modal-in-process>