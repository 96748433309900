<fp-card-body [template]="'secondTemplate'" [typeDashboard]="true" [exit]="true" class="fp-top-up"
    [actionType]="'logout'">
    <ng-template>
        <div class="fp-container-movement">
            <h1 class="fp-h1 fp-list-title">{{ 'title' | translate }}</h1>
            <div *ngIf="!loading && !errorData && movements.length === 0" class="card-empty">
                <fp-item-list-empty iconEmpety="assets/images/movement/movement-empty.svg"
                    [descriptionEmpety]="'list_empty' | translate "></fp-item-list-empty>
            </div>
            <div *ngIf="!loading && !errorData && movements.length !== 0" class="p-relative">
                <div class="fp-card-movement fp-fadeIn" id="scroller"
                    (onScrollDown)="onScrollDown()" [class.is-ios]="isIos">
                    <fp-item-list-movement typeTemplate="secondTemplate"
                        *ngFor="let movement of movements; let indexItem = index" [movement]="movement"
                        [showDivider]="indexItem === movements.length -1 ? false : true">
                    </fp-item-list-movement>
                    <div *ngIf="loadingMore">
                        <div class="card-fake">
                            <div class="row">
                                <div class="skeleton fp-movement-icon purchase">
                                </div>
                                <div class="mr-auto">
                                    <div class="skeleton texts">
                                    </div>
                                    <div class="skeleton texts small">
                                    </div>
                                </div>
                                <div class="skeleton switch-fake"></div>
                                <div class="mr-auto second">
                                    <div class="skeleton texts w-small">
                                    </div>
                                    <div class="skeleton texts w-small small">
                                    </div>
                                </div>
                                <div class="skeleton card-arrow"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="movementsInfo.lastEvaluatedKey.sortKey !== ''" class="movements-blur"></div>
            </div>
            <div *ngIf="loading && !errorData" class="fp-card-movement skeleton ">
                <div class="card-fake" *ngFor="let item of [1,2,3]">
                    <div class="row">
                        <div class="skeleton fp-movement-icon purchase">
                        </div>
                        <div class="mr-auto">
                            <div class="skeleton texts">
                            </div>
                            <div class="skeleton texts small">
                            </div>
                        </div>
                        <div class="skeleton switch-fake"></div>
                        <div class="mr-auto second">
                            <div class="skeleton texts w-small">
                            </div>
                            <div class="skeleton texts w-small small">
                            </div>
                        </div>
                        <div class="skeleton card-arrow"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="!loading && errorData" class="notify-error fp-fadeIn">
                <fp-item-list-empty iconEmpety="assets/images/common/errorService.svg"
                    [descriptionEmpety]="'error.message' | translate " [notifyError]="'true'"></fp-item-list-empty>
                <div style="margin-top: 27px;"></div>
                <div class="notify-error-btn">
                    <button class="fp-btn-primary" type="submit" id="btn-list-create-vehicle" (click)="ngOnInit()">
                        {{ 'error.btn_agin' | translate }}
                    </button>
                </div>
            </div>

            <div class=" form-group-submit">
                <div class="fp-container-btn submit-btn">
                    <button class="fp-btn-primary" type="submit" (click)="navigateToReports()">
                        {{"btn" | translate}}
                    </button>
                </div>
            </div>

        </div>
        <div #elementForScroll></div>
    </ng-template>
</fp-card-body>