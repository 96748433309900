import { IPage } from "@app/models/pages";
import { createAction } from "@ngrx/store";

export const enum TypeActions {
  singupPageString = "[SINGUP] set pages",
  singupAttemptsEditNumbre = "[SINGUP] increments attempts",
}

/**
 * Action to set data page
 */
export const singupSetPage = createAction(
  TypeActions.singupPageString,
  (pages: IPage[]) => ({ pages }),
);

/**
 * Action to set data page
 */
export const signupAttemptsEditNumber = createAction(
  TypeActions.singupPageString,
);
