import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  DocumentsFeatureName,
  DocumentsModuleState,
} from "../state/documents.state";

export const TermsandConditionsRootSelector =
  createFeatureSelector<DocumentsModuleState>(DocumentsFeatureName);

export const selectTermsandConditions = createSelector(
  TermsandConditionsRootSelector,
  (state: DocumentsModuleState) => state.termsandConditionsReducer,
);
