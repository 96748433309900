import { ITransactionsResponse } from "@transactions/modules/status/models/transactionsStatus.interface";

export const TransactionsFeatureName = "TransactionsModuleState";

export type TransactionsModuleState = Readonly<{
  statusTransactionReducer: IStatusTransactionState;
}>;

/**
 * Model to IStatusTransactionState
 */
export interface IStatusTransactionState {
  status: ITransactionsResponse;
  loading: boolean;
  loaded: boolean;
  error: any;
}
/**
 * Model to initialStateStatusTransaction
 */
export const initialStateStatusTransaction: IStatusTransactionState = {
  status: null,
  loading: false,
  loaded: false,
  error: null,
};
