import { createAction } from "@ngrx/store";
import {
  IVehiclesListRequest,
  IVehiclesListResponse,
} from "@vehicles/models/vehicle";

export const enum TypeActionsVehiclelist {
  vehiclesListLoadString = "[VEHICLES LIST] Loading data",
  vehiclesListSaveString = "[VEHICLES LIST] Successful data loaded",
  vehiclesListErrorString = "[VEHICLES LIST] Save error response",
  vehiclesListResettring = "[VEHICLES LIST] Reset data",
}
/**
 * Action to detect event loading vehicles categories data
 */
export const vehiclesListLoad = createAction(
  TypeActionsVehiclelist.vehiclesListLoadString,
  (data: IVehiclesListRequest) => ({ data }),
);
/**
 * Action to detect event loaded vehicles categories data
 */
export const vehiclesListSave = createAction(
  TypeActionsVehiclelist.vehiclesListSaveString,
  (vehicles: IVehiclesListResponse[]) => ({ vehicles }),
);
/**
 * Action to set data error
 */
export const vehiclesListError = createAction(
  TypeActionsVehiclelist.vehiclesListErrorString,
  (error: any) => ({ error }),
);
/**
 * Action to reset data
 */
export const vehiclesListReset = createAction(
  TypeActionsVehiclelist.vehiclesListResettring,
);
