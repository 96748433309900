import { HttpClient } from "@angular/common/http";
import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppFacade } from "@app/app.facade";
import { pageEvent } from "@app/helpers/utag.helpers";
import { WindowToken } from "@app/helpers/windows.helpers";
import { ModalService } from "@app/modules/theme/components/modal/service/modal.service";
import { environment } from "@environment";
import { StorageService } from "@shared/services/storage.service";
import { AuthenticationService } from "@singup/services/authentication.service";
import { ITransactionRequest } from "@tag/models/recharge";
import { PseService } from "@tag/services/pse.service";
import { InputComponent } from "@theme/components/input/input.component";
import { PaymentComponent } from "@theme/components/payment/payment.component";
import { SnackMessageOptions } from "@theme/components/snack-message/snack-message.component";
/**
 * Recharge Component
 */
@Component({
  selector: "fp-recharge-transaction",
  templateUrl: "./recharge.component.html",
  styleUrls: ["./recharge.component.scss"],
})
export class RechargeComponent implements OnInit, OnDestroy {
  @HostBinding("class") fpRechargeTransaction = "fp-recharge-transaction";
  @ViewChild("reminder", { static: true }) tReminder: TemplateRef<any>;
  @ViewChild("notifi", { static: true }) notifi: TemplateRef<any>;
  @ViewChild("inputAmount") inputAmount: InputComponent;
  formTopUp: UntypedFormGroup;
  minAmount: number = Number(environment.pse.minimumAmount);
  typeMessage: SnackMessageOptions = "infoOnlyDescription";
  TopUpErrorMessages = {
    error: [
      { type: "required", message: "form_errors.field.required" },
      { type: "pattern", message: "form_errors.field.pattern" },
      { type: "amountInvalid", message: "form_errors.field.min_amount" },
    ],
  };
  formPaymentInvalid: boolean = true;
  @ViewChild(PaymentComponent)
  paymentComponent: PaymentComponent;
  entitie: string;
  viewMessageError: boolean;
  timeOut: any;
  typeModal = "avalPay";
  avalPayLink: any;
  pendingTransactionMessage = "";
  /**
   * Construtor
   *
   * @param snackBar MatSnackBar
   * @param storageService StorageService
   * @param appFacade AppFacade
   * @param window Window
   * @param pseService PseService
   * @param authenticationService
   */
  constructor(
    private snackBar: MatSnackBar,
    private storageService: StorageService,
    private appFacade: AppFacade,
    @Inject(WindowToken) private window: Window,
    private pseService: PseService,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private http: HttpClient,
  ) {}

  /**
   * Init state to component
   */
  ngOnInit(): void {
    this.formTopUp = new UntypedFormGroup({
      amount: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^[0-9.$ ]+$"),
        Validators.minLength(7),
      ]),
    });
    this.timeOut = setTimeout(() => {
      this.snackBar.openFromTemplate(this.tReminder, {
        horizontalPosition: "center",
        panelClass: "fp-snack-modify",
        verticalPosition: "top",
      });
    }, 2000);

    this.http
      .get("assets/i18n/transactions/recharge/es.json")
      .subscribe((data: any) => {
        this.avalPayLink = data.popup.avalPayLink;
      });
  }
  /**
   * Destroy component
   */
  ngOnDestroy() {
    this.snackBar.dismiss();
    clearTimeout(this.timeOut);
  }
  /**
   * Selection Bank Change
   *
   * @param entitie Name Bankc
   */
  onChangeBank(entitie: string) {
    this.entitie = entitie;
  }
  /**
   * Handel enable button form
   *
   * @param $event Status form payment
   */
  enableBtn($event: boolean) {
    this.formPaymentInvalid = !$event;
  }
  /**
   * Create Transaction Recharge
   */
  async createTransaction(): Promise<any> {
    try {
      this.appFacade.setLoaderShow({
        type: "GENERAL",
      });
      const accountId = await this.storageService.getItem("accountId");

      const infoTransaction: ITransactionRequest = {
        infoTransaction: {
          personTypeId:
            this.paymentComponent.formPurchaseDetail.controls.personType.value,
          amount: Number(
            this.formTopUp.controls.amount.value
              .split("$")[1]
              .replace(/\./g, "")
              .trim(),
          ),
          bankDescription: this.entitie,
          bankId:
            this.paymentComponent.formPurchaseDetail.controls.entities.value,
          accountId,
          transactionTypeId: "2",
        },
      };
      await this.authenticationService.refreshToken();
      const respTransaction = await this.pseService
        .postTransaction(infoTransaction)
        .toPromise();

      if (respTransaction.data.resultMessage.code === "100260") {
        this.window.location.href =
          respTransaction.data.infoTransaction.urlResponse;
      } else {
        this.appFacade.setLoaderHide();
      }
    } catch (error) {
      if (
        error.error &&
        error.error.data &&
        error.error.data.code == "100288"
      ) {
        this.pendingTransactionMessage =
          "Tienes una transacción pendiente - por favor intenta más tarde";
      }

      this.appFacade.setLoaderHide();
      this.snackBar.openFromTemplate(this.notifi, {
        horizontalPosition: "center",
        verticalPosition: "top",
        panelClass: "fp-snack-modify",
        duration: 4000,
      });
    }
  }
  /**
   * Hide notification
   */
  closeMessage(): void {
    this.snackBar.dismiss();
  }
  /**
   * Function to validate Amount
   *
   * @param val value to check with minimun amount
   */
  validateAmount(val: string): void {
    if (!val) {
      this.formTopUp.controls.amount.setErrors({ required: true });
      return;
    }
    let cleanVal = val.split("$")[1];
    if (cleanVal?.length > 1) {
      cleanVal = cleanVal.replace(/\./g, "");
      if (Number(cleanVal) < this.minAmount) {
        this.formTopUp.controls.amount.setErrors({ amountInvalid: true });
        this.formTopUp.controls.amount.markAsTouched();
        this.formTopUp.controls.amount.markAsDirty();
        pageEvent(
          "minimun-amount-error",
          "/transactions/recharge",
          "error-monto-minimo",
        );
      } else {
        this.viewMessageError = false;
      }
      this.inputAmount.updateInput();
    } else {
      this.formTopUp.controls.amount.setErrors({ pattern: true });
    }
  }
  /**
   * Function to validate Amount to buy Tag
   *
   * @param val value to check with minimun amount
   */
  validateAmountTag(val: string): void {
    if (!val) {
      return;
    }
    let cleanVal = val.trim().split("$")[1];
    if (cleanVal?.length > 1) {
      cleanVal = cleanVal.replace(/\./g, "");
      Number(cleanVal) < this.minAmount
        ? (this.viewMessageError = true)
        : (this.viewMessageError = false);
    }
  }

  openModal(): void {
    this.modalService.open("fp-popup-avalpay");
  }

  closeModal(): void {
    this.modalService.close("fp-popup-avalpay", true);
    window.open(this.avalPayLink, "_blank");
  }
}
