import { Injectable } from "@angular/core";

/**
 * Injectable
 */
@Injectable({ providedIn: "root" })
export class ModalService {
  public modals: any[] = [];
  /**
   * Add modal to array
   *
   * @param modal modal
   */
  add(modal: any) {
    this.modals.push(modal);
  }
  /**
   * Remove modal for screen
   *
   * @param id id to identify modal
   */
  remove(id: string) {
    this.modals = this.modals.filter((x) => x.id !== id);
  }
  /**
   * open modal specified by id
   *
   * @param id id to identify modal
   */
  open(id: string) {
    const modal = this.modals.find((x) => x.id === id);
    modal.open();
  }
  /**
   * close modal specified by id
   *
   * @param id id to identify modal
   * @param interno
   */
  close(id: string, interno: boolean) {
    const modal = this.modals.find((x) => x.id === id);
    if (modal !== undefined) {
      modal.close(interno);
    }
  }
}
