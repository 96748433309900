import { IUserInfo } from "@app/models/user-info";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { userInfoFeatureName } from "../state/user-info.state";

export const UserInfoRootSelector =
  createFeatureSelector<IUserInfo>(userInfoFeatureName);

export const selectUserInfo = createSelector(
  UserInfoRootSelector,
  (state: IUserInfo) => state,
);
