import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "fp-gou-redirect",
  templateUrl: "./gou-redirect.component.html",
  styleUrls: ["./gou-redirect.component.scss"],
})
export class GouRedirectComponent {
  @Output() itemClickActive = new EventEmitter<{}>();

  modalAction() {
    this.itemClickActive.emit();
  }
}
