import { Component, OnInit, OnDestroy } from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "@store/actions/app.action";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IOptions } from "./models/options";

@Component({
  selector: "fp-other-options",
  templateUrl: "./other-options.component.html",
  styleUrls: ["./other-options.component.scss"],
})
export class OtherOptionsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  options: IOptions[] = [];
  constructor(
    public translate: TranslateService,
    private appFacade: AppFacade,
  ) {}

  ngOnInit(): void {
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
    this.options = [
      {
        image: "assets/images/other-options/icon-contract.svg",
        text: "Mi contrato",
      },
      {
        image: "assets/images/other-options/icon-legal.svg",
        text: "Mis acuerdos legales",
      },
      {
        image: "assets/images/other-options/icon-cancel-account.svg",
        text: "Cancelar cuenta",
      },
    ];
  }
  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * get data of language selector
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }
}
