import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
/**
 * Pipe Name
 */
@Pipe({
  name: "hours",
})
export class FpDatePipe implements PipeTransform {
  /**
   * Constructor
   *
   * @param datePipe datePipe
   */
  constructor(private datePipe: DatePipe) {}
  /**
   * Transform data
   *
   * @param value value
   * @param arg optional args
   */
  transform(value: any, arg?: string): string {
    if (value) {
      if (arg) {
        const valor = this.datePipe.transform(value, "d/MMM/yyyy");
        return valor.replace(".", "");
      } else {
        const valor = this.datePipe.transform(value, "shortTime");
        return valor.replace(". ", ".");
      }
    }
  }
}
