import { IPages } from "@app/models/pages";
import { IPseBanks } from "@app/models/pse";
import {
  tagActivationPagesConst,
  tagPagesConst,
} from "@core/constants/pages.constant";
import { ITermsandConditions } from "@singup/models/itermsand-conditions";
import { IDeliveryInfo } from "@tag/models/delivery";
import { IRechargeState } from "@tag/models/recharge";
import { ITagNumber } from "@tag/models/tag-number";
import { ITagReservation } from "@tag/models/tag-reservation";
import { ITagTypes } from "@tag/models/tag-types";

/**
 * Set a Name to read chart redux
 */
export const TagFeatureName = "TagState";

/**
 * Grouping to TagState
 */
export type TagModuleState = Readonly<{
  tagPagesReducer: IPages;
  tagActivationPagesReducer: IPages;
  deliveryInfoReducer: IDeliveryInfo;
  tagTermsandConditionsReducer: ITermsandConditions;
  tagTypesReducer: ITagTypes;
  pseBanksReducer: IPseBanks;
  tagRechargeReducer: IRechargeState;
  tagReservationReducer: ITagReservation;
  tagNumberReducer: ITagNumber;
}>;

export const initialTagPages: IPages = {
  pages: [tagPagesConst[0]],
};

export const initialTagActivationPages: IPages = {
  pages: [tagActivationPagesConst[0]],
};

export const initialStateTagTermsandConditions: ITermsandConditions = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};

export const initialStateTagTypes: ITagTypes = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};

export const initialStatePseBanks: IPseBanks = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};

export const initialAddressInfo: IDeliveryInfo = {
  city: null,
  location: "Calle",
  address1: null,
  address2: null,
  address3: null,
  otherAddress: "",
  additionalInformation: "",
  district: null,
  address: null,
};

export const initialStateRecharge: IRechargeState = {
  data: null,
};

export const initialStateTagReservation: ITagReservation = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};

export const initialStateTagNumber: ITagNumber = {
  tagNumber: null,
};
