import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  VehiclesFeatureName,
  VehiclesModuleState,
} from "../state/vehicles.state";

export const VehicleRootSelector =
  createFeatureSelector<VehiclesModuleState>(VehiclesFeatureName);

export const selectVehiclepages = createSelector(
  VehicleRootSelector,
  (state: VehiclesModuleState) => state.vehiclePagesReducer,
);
