import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  DocumentsFeatureName,
  DocumentsModuleState,
} from "../state/documents.state";

export const AddressRootSelector =
  createFeatureSelector<DocumentsModuleState>(DocumentsFeatureName);

export const selectAddressInfo = createSelector(
  AddressRootSelector,
  (state: DocumentsModuleState) => state.addressInfoReducer,
);
