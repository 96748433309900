import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
@Component({
  selector: "fp-info-message",
  templateUrl: "./info-message.component.html",
  styleUrls: ["./info-message.component.scss"],
})
export class InfoMessageComponent {
  @HostBinding("class") fpInfoMessage = "fp-info-message";
  messageSanitizer: SafeHtml;
  @Input() typeClass: string;
  @Input() showInfo: boolean = true;
  @Input() showClose: boolean = true;
  @Output() emitCloseInfo = new EventEmitter<boolean>();
  @Input() set message(text: string) {
    if (text) {
      this.messageSanitizer = this.sanitizer.bypassSecurityTrustHtml(text);
    }
  }
  constructor(private sanitizer: DomSanitizer) {}

  closeInfoSpam(): void {
    this.emitCloseInfo.emit(true);
  }
}
