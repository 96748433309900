import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  DocumentsFeatureName,
  DocumentsModuleState,
} from "../state/documents.state";

export const SingUpRootSelector =
  createFeatureSelector<DocumentsModuleState>(DocumentsFeatureName);

export const selectSingUp = createSelector(
  SingUpRootSelector,
  (state: DocumentsModuleState) => state.singupReducer,
);
