import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { IDataCities } from "@shared/models/cities";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

export const INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputComponent),
  multi: true,
};

export declare type fArgEmptyReturn = (arg0: any) => any;
export declare type fEmptyReturn = () => any;

@Component({
  selector: "fp-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
  providers: [INPUT_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InputComponent implements ControlValueAccessor {
  @HostBinding("class") fpInput = "fp-input";
  @Input() idInput: string;
  @Input() nameInput: string;
  @Input() typeInput: string = "text";
  @Input() label: string;
  @Input() nameLabelTranslate: string;
  @Input() formControl: UntypedFormControl;
  @Input() inputValidationMessages: any;
  @Input() inputClass: string;
  @Input() labelClass: string;

  @Input() placeholder: string;

  @Input() size: number;

  @Input() minlength: number;

  @Input() maxlength: number;

  @Input() tabindex: string;

  @Input() title: string;

  @Input() ariaLabel: string;

  @Input() ariaRequired: boolean;

  @Input() required: boolean;

  @Input() disabled: boolean;

  @Input() readonly: boolean;

  @Input() autocomplete: string = "off";

  @Input() min: number;

  @Input() max: number;
  @Input() autoFocus: boolean = false;

  @Input() regexp: string;
  @Input() pattern: string;
  @Input() inputmode: string;

  @Input() matchRegexp: string;

  @Input()
  matchOnInput: boolean = true;

  @Input()
  matchOnBlur: boolean = true;

  @Input() viewSpaceInput: boolean;

  @Input() viewErrorsInput: boolean;

  @Input() viewIconErrorInput: boolean;

  @Input() viewMessageError: boolean = false;

  @Input() matAutocomplete: boolean = false;

  @Input() currencyMode: boolean = false;

  @Input() valueTranslate: string;

  @ViewChild("ipt") inputViewChild: ElementRef;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFocus: EventEmitter<any> = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onInput: EventEmitter<any> = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onRestInput: EventEmitter<any> = new EventEmitter();
  options: IDataCities[];
  filteredOptions: Observable<IDataCities[]>;
  @Input() set list(text: any[]) {
    if (text) {
      this.options = text;
      this.filteredOptions = this.formControl.valueChanges.pipe(
        startWith(""),
        map(() => {
          return this.filter(this.formControl.value);
        }),
      );
    }
  }
  value: any;
  focused: boolean;
  optionSelected: IDataCities = null;

  constructor(
    public translate: TranslateService,
    private store: Store<any>,
    private ref: ChangeDetectorRef,
  ) {
    this.store.select("language").subscribe((actions) => {
      this.translate.use(actions.language);
    });
  }

  onModelChange: fArgEmptyReturn = () => {};

  onModelTouched: fEmptyReturn = () => {};

  onInputFocus(event: Event): void {
    if (this.readonly) {
      return;
    }
    this.focused = true;
    this.onFocus.emit(event);
  }

  onPaste(event: Event): void {
    if (this.readonly) {
      return;
    }
    setTimeout(() => {
      this.updateModel(event);
    }, 0);
  }

  onInputBlur(e: Event): void {
    this.focused = false;
    this.onModelTouched();
    this.onBlur.emit(e);

    if (this.inputViewChild.nativeElement.value !== this.value) {
      this.updateModel(e);
      const event = document.createEvent("HTMLEvents");
      event.initEvent("change", true, false);
      this.inputViewChild.nativeElement.dispatchEvent(event);
    }
  }

  onKeyDown(e: KeyboardEvent): void {
    if (this.readonly) {
      return;
    }
    const k = e.which || e.keyCode;
    if (k === 13) {
      // enter
      this.onInputBlur(e);
      this.updateModel(e);
    } else if (k === 27) {
      // escape
      this.updateModel(e);
      e.preventDefault();
    }
  }

  onKeyPress(e: KeyboardEvent): void {
    if (this.readonly) {
      return;
    }

    const k = e.which || e.keyCode;
    if (e.ctrlKey || e.altKey || e.metaKey || k < 32 || (k > 34 && k < 41)) {
      // Ignore
      return;
    }

    this.updateModel(e);
  }

  updateModel(e: Event): void {
    const updatedValue = (e.target as HTMLInputElement).value;
    if (!updatedValue) {
      this.value = updatedValue;
      this.onModelChange(this.value);
    }
  }

  onInputChange(event: Event): void {
    this.onPaste(event);
    this.onInput.emit(event);
    this.optionSelected = null;
  }

  writeValue(value: any): void {
    this.value = value;
    if (this.inputViewChild && this.inputViewChild.nativeElement) {
      this.inputViewChild.nativeElement.value = !this.value ? "" : this.value;
    }
  }

  registerOnChange(fn: fArgEmptyReturn): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: fEmptyReturn): void {
    this.onModelTouched = fn;
  }

  updateInput(): void {
    this.ref.detectChanges();
  }

  selectCity(citi: IDataCities, $event?: any) {
    if ($event && $event.source?.selected) {
      this.optionSelected = citi;
    }
  }

  resetFormControlValue() {
    this.onModelChange(this.value);
    this.onRestInput.emit(true);
    this.inputViewChild.nativeElement.value = null;
    this.formControl.setValue(null);
  }

  filter(value?: string): IDataCities[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.options.filter((option) =>
        option.description.toLowerCase().includes(filterValue),
      );
    } else {
      return this.options;
    }
  }
}
