import { Injectable } from "@angular/core";
import { IClientData } from "@app/models/customer-info";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { DashboardService } from "@shared/services/dashboard.service";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import * as customerInfoActions from "../actions/customer-info.actions";

@Injectable()
/**
 * CustomerInfo Effect
 */
export class CustomerInfoEffect {
  customer$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(customerInfoActions.customerInfoLoad),
      switchMap(() =>
        this.dashboardService.getCustomerInfo().pipe(
          take(1),
          map((resp: IClientData) =>
            customerInfoActions.customerInfoSave(
              resp.data.clientData.clientInfo[0],
            ),
          ),
          catchError((error) =>
            of(customerInfoActions.customerInfoError({ error })),
          ),
        ),
      ),
    ),
  );

  /**
   * Constructor accounts Service Effect
   *
   * @param actions$ Action provider to set data en store
   * @param dashboardService Service to get Data
   */
  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
  ) {}
}
