import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MovementsListComponent } from "./movements-list.component";

export const movementsListRoot = "list";
const routes: Routes = [
  {
    component: MovementsListComponent,
    path: "",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MovementsListRoutingModule {}
