import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ITagInfo, IVehiclesInfo } from "@home/models/home";
import { CommonsService } from "@shared/services/commons.service";

@Component({
  selector: "fp-vehicles-management",
  templateUrl: "./vehicles-management.component.html",
  styleUrls: ["./vehicles-management.component.scss"],
})
export class VehiclesManagementComponent {
  vehicle: IVehiclesInfo;
  @Input() loading: boolean = false;
  @Output() openModalDelete = new EventEmitter<IVehiclesInfo>();
  @Output() emitCloseModal = new EventEmitter<boolean>();
  @Output() emitChangeStatus = new EventEmitter<ITagInfo>();
  @Input() set vehicleInfo(item: IVehiclesInfo) {
    if (item) {
      this.vehicle = item;
      this.loading = false;
    }
  }
  constructor(private commonsService: CommonsService) {}

  closeModal(): void {
    this.emitCloseModal.emit(true);
  }

  changeStatusTag(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.emitChangeStatus.emit(this.vehicle.tagEntityConfirmation);
  }

  /**
   * redirect to Buy tag form
   *
   * @param $event event with vehicle Information
   */
  redirectTobuyTag() {
    this.commonsService.redirectToTagWithVehicleInformation({
      licensePlate: this.vehicle.vehicle.licensePlate,
      vehicleType: this.vehicle.vehicle.categoryValue,
    });
  }

  changeTag(): void {
    this.commonsService.redirectToTagWithVehicleInformation(
      {
        licensePlate: this.vehicle.vehicle.licensePlate,
        vehicleType: this.vehicle.vehicle.categoryValue,
      },
      "/tag/activation",
    );
  }

  deleteVehicle(): void {
    this.openModalDelete.emit(this.vehicle);
  }
}
