import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  DocumentsFeatureName,
  DocumentsModuleState,
} from "../state/documents.state";

export const OtpCodeVinculationRootSelector =
  createFeatureSelector<DocumentsModuleState>(DocumentsFeatureName);

export const selectotpVinculationCode = createSelector(
  OtpCodeVinculationRootSelector,
  (state: DocumentsModuleState) => state.otpCodeVinculationReducer,
);
