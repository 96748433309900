import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ModalService } from "@theme/components/modal/service/modal.service";

@Component({
  selector: "fp-activation-progress",
  templateUrl: "./activation-progress.component.html",
})
export class ActivationProgressComponent {
  @Input() titleModal: string = "modal.title";
  @Input() labelBtnAccept: string = "modal.btn";
  @Input() hideClose: boolean = false;
  @Output() itemClickActive = new EventEmitter<{
    payload: string;
    typeAction: string;
  }>();

  constructor(private modalService: ModalService) {}

  actionModal($event: { payload: string; typeAction: string }) {
    this.itemClickActive.emit($event);
  }
  /**
   * Close modal
   *
   * @param id string id elemnt Html
   */
  closeModal(id: string) {
    this.modalService.close(id, false);
  }
}
