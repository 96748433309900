import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TagFeatureName, TagModuleState } from "../state/tag.state";

export const TagRechargeRootSelector =
  createFeatureSelector<TagModuleState>(TagFeatureName);

export const selectTagNumber = createSelector(
  TagRechargeRootSelector,
  (state: TagModuleState) => state.tagNumberReducer,
);
