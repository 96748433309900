import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TagFeatureName, TagModuleState } from "../state/tag.state";

export const TagTermsandConditionsRootSelector =
  createFeatureSelector<TagModuleState>(TagFeatureName);

export const selectTagTermsandConditions = createSelector(
  TagTermsandConditionsRootSelector,
  (state: TagModuleState) => state.tagTermsandConditionsReducer,
);
