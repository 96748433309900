<fp-card-body [template]="'secondTemplate'" [typeDashboard]="true" [exit]="true" class="fp-top-up"
    [actionType]="'logout'">
    <ng-template>
        <div class="fp-purchase-detail-container">
            <form novalidate [formGroup]="formTopUp" (ngSubmit)="createTransaction()">
                <div class="title_box">
                    <h1 class="fp-h1 top-up title">{{"title" | translate}}</h1>
                    <h2 class="fp-body-one subtitle"> {{"subtitle" | translate}}</h2>
                </div>
                <div class="fp-card-detail">
                    <fp-input #inputAmount class="form-group input-vehicle" labelClass="form-label"
                        [label]="'min_amount' | translate:{minAmount:minAmount | currency:'COP':'': '1.0-0'}"
                        [autoFocus]="false" viewSpaceInput="true" [viewErrorsInput]="true" [viewIconErrorInput]="true"
                        inputClass="input-large" typeInput="text" nameInput="amount" idInput="amount"
                        [formControl]="formTopUp.controls['amount']"
                        [inputValidationMessages]="TopUpErrorMessages.error" [currencyMode]="true"
                        (onBlur)="validateAmount($event.target.value)"
                        (onInput)="validateAmountTag($event.target.value)"
                        [valueTranslate]="minAmount | currency:'COP':'': '1.0-0'" regexp="^[0-9\.\$ ]+$"
                        matchRegexp="([0-9\.\$ ]*)">
                    </fp-input>
                </div>
                <fp-payment [title]="'pay_type'| translate" [labelTypePerson]="'person_type' | translate"
                    [labelBakns]="'available_banks'| translate" [requierTerms]="false"
                    [purchaseInfoMessages]="TopUpErrorMessages.error[0].message| translate"
                    (formValid)="enableBtn($event)" (onChangeBank)="onChangeBank($event)"
                    (onOpenModal)="openModal()"></fp-payment>
                <div #btnContinue class=" form-group-submit">
                    <div class="fp-container-btn submit-btn">
                        <button class="fp-btn-primary" type="submit"
                            [disabled]="formPaymentInvalid || formTopUp.invalid  || viewMessageError">
                            {{"top_up" | translate}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </ng-template>
</fp-card-body>


<fp-popup-avalpay (itemClickActive)="closeModal()"></fp-popup-avalpay>
<ng-template #reminder>
    <fp-snack-message class="fp-snack-message-login" (snackMessageEvent)="closeMessage()"
        [description]="'info_message.description' | translate" [type]="typeMessage">
    </fp-snack-message>
</ng-template>

<ng-template #notifi>
    <fp-snack-message [title]="'alert.title' | translate"
        [description]="pendingTransactionMessage ? pendingTransactionMessage : ('alert.description' | translate)" type="error">
    </fp-snack-message>
</ng-template>