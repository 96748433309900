import { IDocumentTypeService } from "@shared/models/form-validation-messages";

export const AppFeatureName = "language";
export const IdentificationTypesFetureName = "identificationTypes";

/**
 * Model to
 */
export interface IIdentifiTypesState {
  documenTypes: IDocumentTypeService;
  error: any;
  loaded: boolean;
  loading: boolean;
}
