<fp-modal [typeModal]="'custom'" id="fp-vehicles-management" [hideClose]="true" [hideDecorator]="true">
    <ng-template>
        <div class="vehicles-management-colum">
            <div class="vehicles-management-row">
                <button *ngIf="vehicle?.tagEntityConfirmation" class="vehicles-management-option" type="button"
                    id="popup-management-state" (click)="changeStatusTag()">
                    {{ vehicle?.tagEntityConfirmation.currentStatus === 1 ?'Desactivar' : 'Activar'}}
                </button>
                <span class="loader" *ngIf="loading">
                </span>
            </div>
            <button *ngIf="!vehicle?.tagEntityConfirmation" class="vehicles-management-option" type="button"
                id="popup-management-buy" (click)="redirectTobuyTag()">
                Adquirir TAG
            </button>
            <button *ngIf="!vehicle?.tagEntityConfirmation" class="vehicles-management-option" type="button"
                id="popup-management-associate" (click)="changeTag()">
                Asociar TAG
            </button>
            <button class="vehicles-management-option" type="button" id="popup-management-delete" (click)="deleteVehicle()">
                Eliminar
            </button>
        </div>
        <div class="status-line">
            <hr>
        </div>
        <button class="fp-btn-ghost no-space" type="button" id="popup-management-cancel" (click)="closeModal()">
            Cancelar
        </button>
    </ng-template>
</fp-modal>