import { ElementRef, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class BlockScrollService {
  constructor() {}

  public blockScroll(): void {
    document.body.className = "no-overflow";
  }

  public enableScroll(): void {
    document.body.classList.remove("no-overflow");
  }

  public calcWindow(btnCalc: ElementRef): void {
    const bounding = btnCalc.nativeElement.getBoundingClientRect();
    if (
      bounding.bottom <
      (window.innerHeight || document.documentElement.clientHeight)
    ) {
      this.blockScroll();
    } else {
      this.enableScroll();
    }
  }
}
