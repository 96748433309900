import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { IUser } from "@app/models/user";
import { CommonsService } from "@shared/services/commons.service";
import { AuthenticationService } from "@singup/services/authentication.service";
import { from, Observable } from "rxjs";
/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  /**
   * Constructor
   *
   * @param router Router
   * @param jwtHelperService JwtHelperService
   * @param authenticationService Autentication Service
   * @param commonService
   */
  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonsService,
  ) {}
  /**
   * Activate route
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return from(this.verifyToken(state.url.split("?")[0]));
  }
  /**
   * verify existing Token
   *
   * @param route
   */
  async verifyToken(route: string): Promise<any> {
    let value = false;
    let token = "";
    let userCognito: IUser;
    userCognito =
      await this.authenticationService.getCurrentAuthenticatedUser();
    if (userCognito) {
      token = userCognito.data.authenticationResult.idToken;
    }
    if (
      token &&
      token !== "" &&
      !this.authenticationService.tokenExpired(token)
    ) {
      value = true;
      if (this.authenticationService.refreshed) {
        this.authenticationService.refreshToken();
      }
    } else {
      this.commonService.logOut(true, false);
    }
    return Promise.resolve(value);
  }
}
