import { Component } from "@angular/core";
/**
 * Model to
 */
export interface IItemsMenu {
  title: string;
  icon: string;
  router: string;
}

@Component({
  selector: "fp-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.scss"],
})
export class NavMenuComponent {
  itemsMenu: IItemsMenu[] = [
    {
      title: "Inicio",
      icon: "assets/images/nav-menu/home.svg",
      router: "/home",
    },
    {
      title: "Vehículos",
      icon: "assets/images/nav-menu/vehicles.svg",
      router: "/vehicle/list",
    },
    {
      title: "Movimientos",
      icon: "assets/images/nav-menu/movements.svg",
      router: "/movements/list",
    },
    {
      title: "Recargas",
      icon: "assets/images/nav-menu/recharge.svg",
      router: "/transactions/recharge",
    },
    // {
    //   title: "Más",
    //   icon: "assets/images/nav-menu/more.svg",
    //   router: "/options",
    // },
  ];
  constructor() {}
}
