import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TagFeatureName, TagModuleState } from "../state/tag.state";

export const TagRootSelector =
  createFeatureSelector<TagModuleState>(TagFeatureName);

export const selectTagpages = createSelector(
  TagRootSelector,
  (state: TagModuleState) => state.tagPagesReducer,
);

export const selectDeliveryInfo = createSelector(
  TagRootSelector,
  (state: TagModuleState) => state.deliveryInfoReducer,
);
