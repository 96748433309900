import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { AppFacade } from "@app/app.facade";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "@store/actions/app.action";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "fp-cancel-account",
  templateUrl: "./cancel-account.component.html",
  styleUrls: ["./cancel-account.component.scss"],
})
export class CancelAccountComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  reasons: string[] = [];
  formCancelAccount: UntypedFormGroup;
  constructor(
    public translate: TranslateService,
    private appFacade: AppFacade,
  ) {}
  /**
   * lifecycle ngOnInit
   */
  ngOnInit(): void {
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
    this.reasons = [
      "Utilizo otros peajes",
      "No tengo vehículo",
      "El portal no es amigable",
      "Registré datos incorrectos",
      "Me confundí de servicios",
      "Otro",
    ];
    this.initForm();
  }
  /**
   * Initialize form
   */
  initForm(): void {
    this.formCancelAccount = new UntypedFormGroup({
      otherReason: new UntypedFormControl("", []),
    });
    this.reasons.forEach((option) => {
      this.formCancelAccount.addControl(
        option,
        new UntypedFormControl(false, []),
      );
    });
  }
  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * get data of language selector
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }
}
