import { createAction, props } from "@ngrx/store";

export type Languages = "es" | "en";
export const enum TypeActionsApp {
  languageString = "[APP COMPONENT] translate lenguaje",
  resetString = "[APP COMPONENT] RESET",
}
export const changeLanguage = createAction(
  TypeActionsApp.languageString,
  props<{ language: Languages }>(),
);

export const resetDataStore = createAction(TypeActionsApp.resetString);
