import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { CommonsService } from "@app/modules/shared/services/commons.service";
import { IMovements } from "@home/models/home";
import { TranslateService } from "@ngx-translate/core";
import { BlockScrollService } from "@shared/services/block-scroll/block-scroll.service";
import { Languages } from "@store/actions/app.action";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthenticationService } from "../../../singup/services/authentication.service";
import { IMovementsRequest, IMovementsResponse } from "../../models/movements";
import { MovementsService } from "../../services/movements.service";
/**
 * Movements Component
 */
@Component({
  selector: "fp-movements",
  templateUrl: "./movements-list.component.html",
  styleUrls: ["./movements-list.component.scss"],
})
export class MovementsListComponent implements OnInit, AfterViewInit {
  @HostBinding("class") fpMovements = "fp-movements";
  destroy$: Subject<boolean> = new Subject<boolean>();
  movements: IMovements[] = [];
  loadingMore: boolean = false;
  loading: boolean;
  errorData: boolean = false;
  isIos: boolean = false;
  username: string = "";
  movementsInfo: IMovementsResponse;
  @ViewChild("elementForScroll") elementForScroll: ElementRef;
  @ViewChild("scrollbarRef", { static: false }) scrollbarRef: any;
  /**
   * Constructor
   *
   * @param movementsService MovementsService
   * @param blockScrollService BlockScrollService
   * @param translate TranslateService
   * @param appFacade AppFacade
   * @param authenticationService
   */
  constructor(
    private movementsService: MovementsService,
    private blockScrollService: BlockScrollService,
    public translate: TranslateService,
    private appFacade: AppFacade,
    private authenticationService: AuthenticationService,
    private commonsService: CommonsService,
  ) {}
  /**
   * ngAfterViewInit to block scroll
   */
  ngAfterViewInit(): void {
    this.blockScrollService.calcWindow(this.elementForScroll);
  }
  /**
   * Call initial methods and values
   */
  async ngOnInit(): Promise<void> {
    this.ifValidEntry();
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
    this.loading = true;
    await this.getMovements({ pageSizeMovements: 15 });
  }
  /**
   * get Movements of Service
   *
   * @param requestData requestData
   */
  async getMovements(requestData: IMovementsRequest): Promise<any> {
    this.username = await this.authenticationService.getUsername();
    requestData.personIdKey = this.username.split("-")[1];
    this.errorData = false;
    try {
      const infoMovements = await this.movementsService
        .getMovements(requestData)
        .toPromise();
      this.movements.push(...JSON.parse(JSON.stringify(infoMovements)).data);

      this.movements.sort((a, b) => b.processDate.localeCompare(a.processDate));
      this.movementsInfo = infoMovements;
    } catch {
      this.errorData = true;
    } finally {
      this.loading = false;
    }
  }
  /**
   * Scroll Down Event to show more movements
   */
  async onScrollDown(): Promise<any> {
    if (!this.loadingMore) {
      this.loadingMore = true;
      if (this.movementsInfo.lastEvaluatedKey.sortKey !== "") {
        await this.getMovements({
          pageSizeMovements: 15,
          personIdKey: this.movementsInfo.lastEvaluatedKey.personIdKey,
          sortKey: this.movementsInfo.lastEvaluatedKey.sortKey,
          partitionKey: this.movementsInfo.lastEvaluatedKey.partitionKey,
          processDate: this.movementsInfo.lastEvaluatedKey.processDate,
        });
        this.loadingMore = false;
      } else {
        this.loadingMore = false;
      }
    }
  }
  /**
   * valid Entry Browsers and devices
   */
  ifValidEntry(): void {
    const isMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isSafari = /Safari/i.test(navigator.userAgent);
    if (isMobile && isSafari) {
      this.isIos = true;
    }
  }

  navigateToReports(): void {
    this.commonsService.navigate("/movements/reports");
  }

  /**
   * get data of language selector
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }
}
