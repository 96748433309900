import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignInFeatureName, SignInModuleState } from "../state/signin.state";

export const SignInTimestampRootSelector =
  createFeatureSelector<SignInModuleState>(SignInFeatureName);

export const selectSignInTimestamp = createSelector(
  SignInTimestampRootSelector,
  (state: SignInModuleState) => state.signInTimestampReducer,
);
