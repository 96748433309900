import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";

import { UntypedFormGroup } from "@angular/forms";
import { MatTooltip } from "@angular/material/tooltip";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { InputComponent } from "../input/input.component";
import { ModalService } from "./service/modal.service";

@Component({
  selector: "fp-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, OnDestroy {
  @HostBinding("class") fpModalComponent = "fp-modal-component";
  @ContentChild(TemplateRef) tplRef: TemplateRef<any>;
  @Input() id: string;
  @Input() title: string;
  @Input() iconModal: string;
  @Input() iconModalConfimr: string;
  @Input() typeModal: string = "defaul";
  @Input() showConfirm: boolean = false;
  @Input() typeModalBig: boolean = false;
  @Input() btnAccept: string;
  @Input() btnDownload: string;
  @Input() titleEdit: string;
  @Input() typeTextEdit: string;
  @Input() labelTypeEdit: string;
  @Input() hideClose: boolean = false;
  @Input() hideDecorator: boolean = false;
  @Input() titleConfirm: string;
  @Input() redirect: boolean = false;
  innerHtmlTextConfirm: SafeHtml;
  @Input() set textConfirm(text: string) {
    if (text) {
      this.innerHtmlTextConfirm = this.sanitizer.bypassSecurityTrustHtml(text);
    }
  }
  @Input() maxlength: string;
  @Input() editInfoModalMessages: { error: [{}] };
  @Output() closeModalInterno = new EventEmitter<boolean>();
  @Output() actionModal = new EventEmitter<{
    typeAction: string;
    payload: string;
  }>();
  @Input() typeEditRegexp: string;
  @Input() typeEditmatchRegexp: string;
  @Input() formEditInfo: UntypedFormGroup;
  @Input() typeEdit: string;
  infoGeneric: string;
  @Input() set infoUserEdit(info: string) {
    if (info) {
      this.infoGeneric = info;
    }
  }
  @Input() btnExit: string;
  innerHtmlContent: SafeHtml;
  viewMessageError: boolean = false;
  @Input() set innerHtmlContentString(text: string) {
    if (text) {
      this.innerHtmlContent = this.sanitizer.bypassSecurityTrustHtml(text);
    }
  }
  @ViewChild("inputModal", { static: false }) inputModal: InputComponent;
  @ViewChild(MatTooltip) tooltip: MatTooltip;
  private element: any;

  @Input() nureValue: string;
  tooltipMensaje: string = "";
  mostrarTooltip2: boolean = false;

  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    private sanitizer: DomSanitizer,
  ) {
    this.element = el.nativeElement;
  }
  /**
   * init id with service
   */
  ngOnInit(): void {
    if (!this.id) {
      return;
    }
    document.body.appendChild(this.element);
    this.modalService.add(this);
  }
  /**
   * destroy elements then close component
   */
  ngOnDestroy(): void {
    this.close(null);
    this.modalService.remove(this.id);
    this.element.remove();
  }
  /**
   * open modal
   */
  open(): void {
    this.element.style.display = "block";
    document.body.classList.add("fp-modal-open");
    if (this.typeModal === "editInfo") {
      this.formEditInfo.controls.generic.setValue(this.infoGeneric);
      this.formEditInfo.get("generic").updateValueAndValidity();
      this.inputModal.updateInput();
      this.viewMessageError = false;
    }
  }
  /**
   * close Modal
   *
   * @param internal internal value
   */
  close(internal: boolean = true): void {
    this.element.style.display = "none";
    document.body.classList.remove("fp-modal-open");
    switch (internal) {
      case true:
        this.closeModalInterno.emit(true);
        break;
      case false:
        this.closeModalInterno.emit(false);
        break;
      default:
        break;
    }
  }
  /**
   * emit Action Modal to father component
   *
   * @param action action to emit
   * @param payload additional string
   */
  emitActionModal(action: string, payload: string = null): void {
    this.actionModal.emit({
      typeAction: action,
      payload,
    });
  }
  /**
   * valid Form
   */
  validForm(): void {
    if (
      this.formEditInfo.invalid ||
      this.formEditInfo.controls.generic.value === this.infoGeneric
    ) {
      return;
    }
    this.emitActionModal("update", this.formEditInfo.controls.generic.value);
  }
  /**
   * input event
   *
   * @param $event event to identify insert data
   * @param inputModal modal formControl
   */
  inputEvent($event, inputModal): void {
    if (
      $event.inputType === "insertFromPaste" ||
      $event.inputType === "insertText"
    ) {
      if (
        inputModal.formControl.value.length > 0 &&
        inputModal.formControl.value[0] !== "3"
      ) {
        this.viewMessageError = true;
        this.setErrorInput();
      } else {
        this.viewMessageError = false;
      }
    }
    return;
  }

  /**
   * Set errot to Input in form
   */
  setErrorInput(): void {
    this.formEditInfo.get("generic").setErrors({ pattern: true });
    this.formEditInfo.get("generic").markAsTouched();
    this.inputModal.updateInput();
  }

  copyText(): void {
    navigator.clipboard
      .writeText(this.nureValue)
      .then(() => {
        this.mostrarTooltip("Nure Copiado!");
      })
      .catch((err) => {
        this.mostrarTooltip("Error al copiar");
      });
  }

  mostrarTooltip(mensaje: string): void {
    this.tooltip.message = mensaje;
    this.tooltip.show();

    setTimeout(() => this.tooltip.hide(), 2000); // Oculta el tooltip después de 2 segundos
  }
}
