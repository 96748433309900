import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SingupGuard } from "./guards/singup.guard";

export const signUpRootRoute = "signup";

const routes: Routes = [
  {
    path: "",
    redirectTo: "initial",
    pathMatch: "full",
  },
  {
    path: "initial",
    loadChildren: () =>
      import("./modules/register/register.module").then(
        (m) => m.RegisterModule,
      ),
  },
  {
    path: "address",
    loadChildren: () =>
      import("./modules/user-address/user-address.module").then(
        (m) => m.UserAddressModule,
      ),
    canActivate: [SingupGuard],
  },
  {
    path: "createpassword",
    loadChildren: () =>
      import("./modules/create-password/create-password.module").then(
        (m) => m.CreatePasswordModule,
      ),
    canActivate: [SingupGuard],
  },
  {
    path: "otpcode",
    loadChildren: () =>
      import("./modules/otp-code/otp-code.module").then((m) => m.OtpCodeModule),
    canActivate: [SingupGuard],
  },
  {
    path: "successful",
    loadChildren: () =>
      import("./modules/successful/successful.module").then(
        (m) => m.SuccessFulModule,
      ),
    canActivate: [SingupGuard],
  },
  {
    path: "vinculation-error",
    loadChildren: () =>
      import("./modules/vinculation-error/vinculation-error.module").then(
        (m) => m.VinculationErrorModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SingUpRoutingModule {}
