import { IPages } from "@app/models/pages";
import { IOtpVinculation, ITimerCode } from "@otp-code/models/otp-code";
import { IProspectsInvalid, IRegister } from "@register/models/register";
import { IHabeasData } from "@singup/models/habeas-data";
import { ITermsandConditions } from "@singup/models/itermsand-conditions";
import { ITemporalPassword } from "@singup/models/sing-up";
import { IAddressInfo } from "../../models/address";

/**
 * Set a Name to read chart redux
 */
export const DocumentsFeatureName = "SingUpState";

/**
 * Grouping to DocumentsState
 */
export type DocumentsModuleState = Readonly<{
  termsandConditionsReducer: ITermsandConditions;
  habeasDataReducer: IHabeasData;
  registerReducer: IRegister;
  prospectsInvalid: IProspectsInvalid;
  singupReducer: IPages;
  otpCodeReducer: ITimerCode;
  otpCodeVinculationReducer: IOtpVinculation;
  temporalPasswordReducer: ITemporalPassword;
  addressInfoReducer: IAddressInfo;
}>;
/**
 * Initialize state to Terms and Conditions
 */
export const initialStateITermsandConditions: ITermsandConditions = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
/**
 * Initialize state to HabeasData
 */
export const initialStateIHabeasData: IHabeasData = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
