import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { Observable } from "rxjs";
import {
  IMovementsDashboardRequest,
  IMovementsRequest,
} from "../models/movements";

@Injectable({
  providedIn: "root",
})
export class MovementsService {
  constructor(private http: HttpClient) {}

  /**
   * get Movements
   *
   * @param data data Request Params
   */
  getMovements(data: IMovementsRequest): Observable<any> {
    const params = `?pageSizeMovements=${data.pageSizeMovements}&personId=${
      data.personIdKey ? data.personIdKey : ""
    }&sortKey=${data.sortKey ? data.sortKey : ""}&partitionKey=${
      data.partitionKey ? data.partitionKey : ""
    }&processDate=${data.processDate ? data.processDate : ""}`;
    return this.http.get(
      environment.api_key.payments.back_url +
        environment.endpoints.movements.init +
        params,
      {
        params: new CustomHttpParams("api-pn-payments-apiKey-token-noctype"),
      },
    );
  }

  /**
   * get Movements dashboard
   *
   * @param data data Request Params
   */
  getMovementsDashboard(data: IMovementsDashboardRequest): Observable<any> {
    const params = `?personId=${data.personId}&account=${data.account}`;
    return this.http.get(
      environment.api_key.payments.back_url +
        environment.endpoints.movementsDashboard.init +
        params,
      {
        params: new CustomHttpParams("api-pn-payments-apiKey-token-noctype"),
      },
    );
  }
}
