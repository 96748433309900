import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ITagInfo, IVehiclesInfo } from "@home/models/home";
import { CommonsService } from "@shared/services/commons.service";
import { IVehicleInformation } from "@vehicles/models/vehicle";

export type ItemTemplate = "firstTemplate" | "secondTemplate";

@Component({
  selector: "fp-item-list-vehicle",
  templateUrl: "./item-list-vehicle.component.html",
  styleUrls: ["./item-list-vehicle.component.scss"],
})
export class ItemListVehicleComponent {
  @Input() vehicle: IVehiclesInfo;
  @Input() indexEven: boolean;
  @Input() set resetData(reset: boolean) {
    if (reset) {
      this.loading = false;
    }
  }
  @Input() typeTemplate: ItemTemplate = "firstTemplate";
  @Output() itemClickActive = new EventEmitter<ITagInfo>();
  @Output() vehicleInformation = new EventEmitter<IVehicleInformation>();
  @Output() openModalManagement = new EventEmitter<IVehiclesInfo>();
  @Output() openModalDelete = new EventEmitter<IVehiclesInfo>();
  loading: boolean = false;
  moreOptions: boolean = false;

  constructor(private commonsService: CommonsService) {}
  changeStatusTag() {
    if (this.loading) {
      return;
    }
    this.itemClickActive.emit(this.vehicle.tagEntityConfirmation);
    this.loading = true;
  }

  buyTag(): void {
    this.vehicleInformation.emit({
      licensePlate: this.vehicle.vehicle.licensePlate,
      vehicleType: this.vehicle.vehicle.categoryValue,
    });
  }

  /**
   * redirect to change tag
   */
  changeTag(): void {
    this.commonsService.redirectToTagWithVehicleInformation(
      {
        licensePlate: this.vehicle.vehicle.licensePlate,
        vehicleType: this.vehicle.vehicle.categoryValue,
      },
      "/tag/activation",
    );
  }

  /**
   * redirect to associated tag
   */
  associatedTag(): void {
    this.commonsService.redirectToTagWithVehicleInformation(
      {
        licensePlate: this.vehicle.vehicle.licensePlate,
        vehicleType: this.vehicle.vehicle.categoryValue,
      },
      "/tag/activation",
    );
  }

  deleteVehicle(): void {
    this.openModalDelete.emit(this.vehicle);
  }

  toggleMoreOptions(): void {
    this.moreOptions = !this.moreOptions;
  }

  openModalMoreOptions(): void {
    this.openModalManagement.emit(this.vehicle);
  }
}
