import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  DocumentsFeatureName,
  DocumentsModuleState,
} from "../state/documents.state";

export const HabeasDataRootSelector =
  createFeatureSelector<DocumentsModuleState>(DocumentsFeatureName);

export const selectHabeasData = createSelector(
  HabeasDataRootSelector,
  (state: DocumentsModuleState) => state.habeasDataReducer,
);
