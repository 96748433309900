import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  OnDestroy,
} from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "@store/actions/app.action";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "fp-popup-activate-progress",
  templateUrl: "./popup-activate-progress.component.html",
})
export class PopupActivateProgressComponent implements OnInit, OnDestroy {
  @Output() itemClickActive = new EventEmitter<{}>();
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public translate: TranslateService,
    private appFacade: AppFacade,
  ) {}

  ngOnInit(): void {
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
  }

  /**
   * Destroy subscribes
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  /**
   * modal action
   *
   * @param $event event
   */
  actionModal($event: {}) {
    this.itemClickActive.emit($event);
  }

  /**
   * get data of language selector
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }
}
