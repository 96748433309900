<fp-card-body [template]="'fourdTemplate'" exit="true" [typeDashboard]="'true'" [actionType]="'logout'">
    <ng-template>
        <div class="fp-layout">
            <div class="d-flex column container-home one">
                <div class="container-column-one fp-fadeIn">
                    <img class="icon-home" src="assets/images/home/logoHome.svg" alt="icono facilpass" *ngIf="userInfo">
                    <h1 class="fp-h1 fp-user-name" *ngIf="userInfo">{{'hello' |
                        translate}},<span>
                            {{userInfo?.userClientInfo[0]?.name.split(" ")[0]}}</span></h1>
                    <h2 class="fp-body-one subtitle-home" *ngIf="userInfo">{{ 'last_connection' | translate }}
                        <span>{{userInfo?.userClientInfo[0]?.lastAccesDate | date }} -
                            {{userInfo?.userClientInfo[0]?.lastAccesDate | hours }}</span>
                    </h2>

                    <ng-container *ngIf="accountsState?.error">
                        <div class="container-error">
                            <div class="notify-error">
                                <fp-item-list-empty iconEmpety="assets/images/common/errorService.svg"
                                    [descriptionEmpety]="'error.message' | translate " [notifyError]="'true'">
                                </fp-item-list-empty>
                                <div style="margin-top: 27px;"></div>
                                <div class="notify-error-btn">
                                    <button class="fp-btn-primary try" type="submit" id="btn-list-create-vehicle"
                                        (click)="getAccounts()">
                                        {{ 'error.btn_agin' | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Inicio Accounts Cards -->
                    <ng-container *ngIf="accountsState?.data !==null && accountsState?.data.length !== 0">
                        <div class="fp-card-home" *ngFor="let account of accountsState.data">
                            <h6 class="fp-card-home-title">{{ 'balance' | translate }}</h6>
                            <div class="d-flex row j-between center">
                                <h1 class="fp-card-home-currency">
                                    ${{ account.balance | currency : 'COP' : '' : '1.0-0' }}</h1>
                                <div class="d-flex column center" (click)="navigatetoRoute('/transactions/recharge')">
                                    <button class="fp-btn-fap">
                                        <img src="assets/images/home/currency.svg" alt="icono currency">
                                    </button>
                                    <span class="subtitle-home bold">{{ 'recharge' | translate }}</span>
                                </div>
                            </div>
                            <h2 class="fp-body-one subtitle-home py">
                                {{ account.accountType === 'PRE' ? ('accountPRE' | translate) : ('accountPRE-REC' |
                                translate) }}
                                <span>No. {{account.accountId}}</span>
                            </h2>

                            <h2 class="fp-body-one subtitle-home py-second">NURE <span>{{account.nure}}</span></h2>
                            <div class="circle-decorator"></div>
                        </div>
                    </ng-container>
                    <!-- Fin Accounts Cards -->

                    <!-- Inicio Banner Cards -->
                    <ng-container *ngIf="accountsState?.data !==null && accountsState?.data.length !== 0 && accountType === 'PRE'">
                        <div class="fp-card-banner">

                            <div class="d-flex row j-between center">
                                <div class="d-flex column">
                                    <h6 class="fp-card-banner-title">{{ 'bannerTitle' |
                                        translate }}</h6>
                                    <img class="fp-card-banner-vector" src="assets/images/home/vector.svg" alt="vector">
                                    <h2 class="fp-card-banner-subtitle">
                                        {{ 'bannerDescription' | translate }}
                                    </h2>
                                </div>
                                <div class="d-flex column img-banner">
                                    <img src="assets/images/home/imgGirl.svg" alt="cuenta-pre-rec">
                                </div>
                                <button class="fp-btn-white btn-banner" type="button" (click)="goTo()">
                                    {{ 'bannerButon' | translate }}
                                    <img src="assets/images/home/more.svg" alt="icono facilpass">
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <!-- Fin Banner Cards -->

                    <!-- Inicio Recarga Automaitca -->
                    <ng-container *ngIf="accountType === 'PRE-REC'">
                        <div class="d-flex row j-between m-home-movement" *ngIf="movementsState?.data !==null">
                            <h1 class="fp-h1 title-home-movement">{{ 'automatic-charges' | translate }}</h1>
                        </div>
                        <div class="status-line">
                            <hr>
                        </div>

                        <ng-container *ngIf="movementsState?.data && movementsState.data.length !== 0 ">
                            <fp-automatic-movements [financialProduct]="accountsState.data[0].financialProduct">
                            </fp-automatic-movements>
                        </ng-container>

                        <div class="items-empty-movement"
                            *ngIf="movementsState?.data && movementsState.data.length === 0">
                            <fp-item-list-empty iconEmpety="assets/images/movement/movement-empty.svg"
                                [descriptionEmpety]="'list_empty' | translate "></fp-item-list-empty>
                        </div>
                    </ng-container>
                    <!-- Fin Recarga Automaitca -->

                    <div ngClass="container-snack-message-info" *ngIf="accountType === 'PRE-REC' && visibleSnack">

                        <img ngClass="icon-snack-message-info" src="assets/images/common/icon-info.svg" alt="info">

                        <div class="container-snack-message-title">

                            <div ngClass="title-snack-message-info">
                                {{ 'titleSnack' | translate }}
                            </div>

                            <div ngClass="description-snack-message-info">
                                {{ 'descriptionSnack' | translate }}
                            </div>

                        </div>

                        <img class="icon-snack-message-back" id="icon-snack-message-back"
                            src="assets/images/common/icon-back.svg" alt="Cerrar" (click)="closeSnack()">

                    </div>

                    <!-- Inicio Movements -->
                    <div class="d-flex row j-between m-home-movement"
                        *ngIf="movementsState?.data !==null || movementsState?.error !==null">
                        <h1 class="fp-h1 title-home-movement">{{ 'moves' | translate }}</h1>
                        <button class="fp-btn-white" type="button" (click)="navigatetoRoute('/movements')">{{ 'more' |
                            translate }} <img src="assets/images/home/more.svg" alt="icono facilpass"></button>
                    </div>
                    <div class="status-line">
                        <hr>
                    </div>
                    <ng-container *ngIf="movementsState?.data && movementsState.data.length !== 0 ">
                        <fp-item-list-movement *ngFor="let movement of movementsState.data" [movement]="movement">
                        </fp-item-list-movement>
                    </ng-container>
                    <div class="items-empty-movement" *ngIf="movementsState?.data && movementsState.data.length === 0">
                        <fp-item-list-empty iconEmpety="assets/images/movement/movement-empty.svg"
                            [descriptionEmpety]="'list_empty' | translate "></fp-item-list-empty>
                    </div>
                    <ng-container *ngIf="movementsState?.error">
                        <div class="notify-error">
                            <fp-item-list-empty iconEmpety="assets/images/common/errorService.svg"
                                [descriptionEmpety]="'error.message' | translate " [notifyError]="'true'">
                            </fp-item-list-empty>
                            <div style="margin-top: 27px;"></div>
                            <div class="notify-error-btn">
                                <button class="fp-btn-primary try" type="submit" id="btn-list-create-vehicle"
                                    (click)="getMovements()">
                                    {{ 'error.btn_agin' | translate }}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <!-- Fin Movements -->
                </div>
                <div>
                    <div class="container-column-one">
                        <div class="skeleton icon-home" *ngIf="!userInfo"></div>
                        <h1 class="skeleton fp-h1 fp-user-name" *ngIf="!userInfo"></h1>
                        <h2 class="skeleton fp-body-one subtitle-home" *ngIf="!userInfo" style="width: 66%;"></h2>
                        <div class="skeleton fp-card-home" *ngIf="!accountsState?.data && !accountsState?.error">
                            <h6 class="skeleton fp-card-home-title"></h6>
                            <div class="d-flex row j-between center">
                                <h1 class="skeleton fp-card-home-currency"></h1>
                                <div class="d-flex column center">
                                    <div class="skeleton circle-fake"></div>
                                    <span class="skeleton subtitle-home bold"></span>
                                </div>
                            </div>
                            <h2 class="skeleton fp-body-one subtitle-home py"></h2>
                        </div>

                        <!-- Inicio Skeleton Banner -->
                        <div class="skeleton fp-card-home"
                            *ngIf="!accountsState?.data && !accountsState?.error && accountType === 'PRE'">
                            <h1 class="skeleton fp-h1 title-home-movement"></h1>
                            <div class="d-flex row j-between center">
                                <h1 class="skeleton fp-h1 title-home-movement"></h1>
                                <div class="d-flex column center">
                                    <div class="skeleton square-fake"></div>
                                </div>
                            </div>
                            <h2 class="skeleton fp-body-one subtitle-home py"></h2>
                        </div>
                        <!-- Inicio Skeleton Banner -->

                        <div class="d-flex row j-between m-home-movement"
                            *ngIf="!movementsState?.data && !movementsState?.error">
                            <h1 class="skeleton fp-h1 title-home-movement"></h1>
                            <button class="skeleton fp-btn-white" type="button"></button>
                        </div>

                        <div *ngIf="!movementsState?.data && !movementsState?.error">
                            <div *ngFor="let item of [1,2,3]" class="row-fake">
                                <div class="skeleton circle">
                                </div>
                                <div class="colum mr-auto">
                                    <div class="skeleton texts">
                                    </div>
                                    <div class="skeleton texts small">
                                    </div>
                                </div>
                                <div class="skeleton switch-fake"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="circulo_border"></div>
            </div>


            <div class="container-home second">
                <div class="fp-card-home second fp-fadeIn"
                    *ngIf="vehiclesState?.data !==null || vehiclesState?.error !==null">
                    <h6 class="fp-card-home-title second">{{ 'vehicles' | translate }}</h6>
                    <ng-container *ngIf="vehiclesState.data && vehiclesState.data.length !== 0 ">
                        <div class="container-vehicles">
                            <div class="container-item-vehicles" *ngFor="let vehicle of vehiclesState.data">
                                <fp-item-list-vehicle typeTemplate="firstTemplate" [vehicle]="vehicle"
                                    (itemClickActive)="changeStatusTag($event)"
                                    (vehicleInformation)="goTobuyTag($event)" [resetData]="error">
                                </fp-item-list-vehicle>
                            </div>
                        </div>
                    </ng-container>
                    <div class="items-empty-vehicle" *ngIf="vehiclesState.data && vehiclesState.data.length === 0">
                        <fp-item-list-empty iconEmpety="assets/images/vehicle/vehicle-empty.svg"
                            [descriptionEmpety]="'first_vehicle' | translate "></fp-item-list-empty>
                    </div>
                    <button class="fp-btn-primary home-submit-btn" *ngIf="vehiclesState.data"
                        [class.empty]="vehiclesState.data && vehiclesState.data.length === 0" type="button"
                        id="btn-create-vehicle-home" (click)="navigatetoRoute('/vehicle/create')">
                        {{ 'create' | translate }}
                    </button>

                    <ng-container *ngIf="vehiclesState?.error">
                        <div class="notify-error">
                            <fp-item-list-empty iconEmpety="assets/images/common/errorService.svg"
                                [descriptionEmpety]="'error.message' | translate " [notifyError]="'true'">
                            </fp-item-list-empty>
                            <div style="margin-top: 27px;"></div>
                            <div class="notify-error-btn">
                                <button class="fp-btn-primary try" type="submit" id="btn-list-create-vehicle"
                                    (click)="selectVehiclesList()">
                                    {{ 'error.btn_agin' | translate }}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="skeleton fp-card-home second" *ngIf="!vehiclesState?.data && !vehiclesState?.error">
                    <h1 class="fp-card-home-title second">
                        <div class="skeleton title-fake center"></div>
                    </h1>
                    <div class="row-fake">
                        <div class="skeleton circle">
                        </div>
                        <div class="colum mr-auto">
                            <div class="skeleton texts">
                            </div>
                            <div class="skeleton texts small">
                            </div>
                        </div>
                        <div class="skeleton switch-fake btn"></div>
                    </div>
                    <div class="skeleton switch-fake btn-big">
                    </div>
                </div>

            </div>

        </div>
    </ng-template>
</fp-card-body>

<fp-popup-activate-progress (itemClickActive)="navigatetoRoute('/transactions/recharge')"></fp-popup-activate-progress>

<ng-template #tempMessageError>
    <fp-snack-message (snackMessageEvent)="closeMessage()" [title]="'Error' " [description]="messageError | translate "
        type="error">
    </fp-snack-message>
</ng-template>