import { Injectable } from "@angular/core";
import { IPage, IPages } from "@app/models/pages";
import { select, Store } from "@ngrx/store";
import {
  IOtpVinculation,
  ITimerCode,
  IVinculateModel,
} from "@otp-code/models/otp-code";
import { IHabeasData } from "@singup/models/habeas-data";
import { ITermsandConditions } from "@singup/models/itermsand-conditions";
import { ITemporalPassword } from "@singup/models/sing-up";
import { Observable } from "rxjs";
import { IAddressInfo } from "../../models/address";
import * as addressActions from "../actions/address.actions";
import * as actionsHabeasData from "../actions/habeas-data.actions";
import * as otpCodeActions from "../actions/otp-code.actions";
import * as registerActions from "../actions/register.actions";
import * as singupActions from "../actions/sing-up.actions";
import * as actionPassword from "../actions/temporal-password.actions";
import * as actionsTC from "../actions/terms-conditions.actions";
import { selectAddressInfo } from "../selectors/address.selector";
import { selectHabeasData } from "../selectors/habeas-data.selector";
import { selectotpVinculationCode } from "../selectors/otp-code-vinculation.selector";
import { selectotpCode } from "../selectors/otp-code.selector";
import { selectSingUp } from "../selectors/sing-up-pages.selector";
import { selectTemporalPassword } from "../selectors/temporal-password.selector";
import { selectTermsandConditions } from "../selectors/terms-conditions.selector";

import { DocumentsModuleState } from "../state/documents.state";

@Injectable()
export class SingUpFacade {
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectHabeasData$: Observable<IHabeasData> = this.store.pipe(
    select(selectHabeasData),
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectTermsandConditions$: Observable<ITermsandConditions> =
    this.store.pipe(select(selectTermsandConditions));

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectHabeasData2$: Observable<IHabeasData> = this.store.pipe(
    select(selectHabeasData),
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectTermsandConditions2$: Observable<ITermsandConditions> =
    this.store.pipe(select(selectTermsandConditions));
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectSingUp$: Observable<IPages> = this.store.pipe(
    select(selectSingUp),
  );
  public selectOtpCodeTimer$: Observable<ITimerCode> = this.store.pipe(
    select(selectotpCode),
  );

  public selectTemporalPassword$: Observable<ITemporalPassword> =
    this.store.pipe(select(selectTemporalPassword));

  public selectOtpCodeVinculation$: Observable<IOtpVinculation> =
    this.store.pipe(select(selectotpVinculationCode));

  public selectAddressInfo$: Observable<IAddressInfo> = this.store.pipe(
    select(selectAddressInfo),
  );
  /**
   * Constructor
   *
   * @param store Redux Store tyoe DocumentModule
   */
  constructor(private store: Store<DocumentsModuleState>) {}

  public toggleCheckState(key: number): void {
    this.store.dispatch(actionsHabeasData.habeasDataToggleCheckOptional(key));
  }

  public setCheckState(value: boolean): void {
    this.store.dispatch(actionsHabeasData.habeasDataSetCheckOptional(value));
  }

  public setCheckHabeasDataState(value: boolean): void {
    this.store.dispatch(actionsHabeasData.setHabeasDataCheckRequired(value));
  }

  public setCheckTermsandConditionsState(value: boolean): void {
    this.store.dispatch(actionsTC.setTermsandConditionsCheckRequired(value));
  }
  public setSingupPages(pages: IPage[]) {
    this.store.dispatch(singupActions.singupSetPage(pages));
  }
  /**
   * Set event to load Terms and Conditions
   *
   * @param documentRequestParams Set an object with data to filter documents
   */
  public getTermandConditions(): void {
    this.store.dispatch(actionsTC.termsandConditionsLoad());
  }
  /**
   * Set event to load Habeas Data
   *
   * @param habeasDataRequestParams Set an object eith data to get habeas data
   */
  public getHabeasData(): void {
    this.store.dispatch(actionsHabeasData.habeasDataLoad());
  }

  public setOtpCodeTimer(timer: ITimerCode): void {
    this.store.dispatch(otpCodeActions.OtpTimer(timer));
  }

  public setAttemptsEditNumber(): void {
    this.store.dispatch(
      otpCodeActions.SetAttemptsEditNumber({ attemptsEditNumber: 1 }),
    );
  }

  public setTemporalPassword(data: ITemporalPassword): void {
    this.store.dispatch(actionPassword.TemporalPassword(data));
  }

  public setOtpVinculation(data: IVinculateModel): void {
    this.store.dispatch(otpCodeActions.otpCodeVinculationLoad(data));
  }

  public resetUserData(): void {
    this.store.dispatch(registerActions.resetUserData());
  }

  public resetOtpVinculationData(): void {
    this.store.dispatch(otpCodeActions.resetOtpCodeVinculationData());
  }

  public setAddressInfo(deliveryInfo: IAddressInfo) {
    this.store.dispatch(addressActions.setAddressInformation(deliveryInfo));
  }
}
