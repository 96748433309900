import { Injectable } from "@angular/core";
import { IUserInfoData } from "@app/models/user-info";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { DashboardService } from "@shared/services/dashboard.service";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import * as userInfoActions from "../actions/user-info.actions";

@Injectable()
/**
 * UserInfo Effect
 */
export class UserInfoEffect {
  accounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(userInfoActions.userInfoLoad),
      switchMap((action) =>
        this.dashboardService.getUserInfo(action.data).pipe(
          take(1),
          map((resp: IUserInfoData) =>
            userInfoActions.userInfoSave(resp.data.result_message),
          ),
          catchError((error) => of(userInfoActions.userInfoError({ error }))),
        ),
      ),
    ),
  );

  /**
   * Constructor userInfo Service Effect
   *
   * @param actions$ Action provider to set data en store
   * @param dashboardService Service to get Data
   */
  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
  ) {}
}
