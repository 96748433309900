import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { ICities } from "@shared/models/cities";
import { CitiesService } from "@shared/services/cities.service";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import * as citiesActions from "../actions/cities.actions";

@Injectable()
/**
 * Cities Effect
 */
export class CitiesEffect {
  vehicleTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(citiesActions.citiesLoad),
      switchMap(() =>
        this.citiesService.getCities().pipe(
          take(1),
          map((resp: ICities) => citiesActions.citiesSave(resp.data)),
          catchError((error) => of(citiesActions.citiesError({ error }))),
        ),
      ),
    ),
  );

  /**
   * Constructor cities Service Effect
   *
   * @param actions$ Action provider to set data en store
   * @param citiesService Service to get Data
   */
  constructor(
    private actions$: Actions,
    private citiesService: CitiesService,
  ) {}
}
