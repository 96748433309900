import { createAction, props } from "@ngrx/store";

export const documentTypeLoad = createAction(
  "[IDENTIFICATION TYPES] identification documen types loading",
);
export const documentTypeSuccess = createAction(
  "[IDENTIFICATION TYPES] identification documen types loaded",
  props<{ documenTypes }>(),
);
export const documentTypeError = createAction(
  "[IDENTIFICATION TYPES] identification documen types error",
  props<{ error }>(),
);
