import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignInFeatureName, SignInModuleState } from "../state/signin.state";

export const SignInPagesRootSelector =
  createFeatureSelector<SignInModuleState>(SignInFeatureName);

export const selectSignInpages = createSelector(
  SignInPagesRootSelector,
  (state: SignInModuleState) => state.singInPagesReducer,
);
