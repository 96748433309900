<fp-card-body [template]="'secondTemplate'" urlBack="/options">
    <ng-template>
        <div class="cancel-account-body">
            <h1 class="fp-h1">{{ "title" | translate }}</h1>
            <h2 class="fp-body-one "> {{ "subtitle" | translate}}</h2>
            <form novalidate [formGroup]="formCancelAccount">
                <div class="form-group">
                    <div class="reasons" *ngFor="let reason of reasons">
                        <div class="checkbox">
                            <fp-checkbox chkboxId="check{{reason}}" name="check{{reason}}" inputId="check{{reason}}"
                                [formControl]="formCancelAccount.controls[reason]" binary="true">
                            </fp-checkbox>
                        </div>
                        <div class="text">
                            <a> {{reason}}</a>
                        </div>
                    </div>
                    <div *ngIf="formCancelAccount.controls['Otro'].value === true">
                        <fp-input class="form-group" styleClass="form-group" labelClass="form-label"
                            [nameLabelTranslate]="'which' | translate" viewSpaceInput="true" [viewErrorsInput]="true"
                            [viewIconErrorInput]="true" inputClass="input-large" [typeInput]="'text'"
                            nameInput="inputOtherReason" idInput="inputOtherReason"
                            [formControl]="formCancelAccount.controls['otherReason']"
                            regexp="^[A-Za-zÁÉÍÓÚáéíóúñÑ\-\/\-0-9-(-)-,-.-_ ]+$"
                            matchRegexp="([A-Za-zÁÉÍÓÚáéíóúñÑ\-\/\-0-9-(-)-,-.-_ ])" [matchOnInput]="false">
                        </fp-input>
                    </div>
                </div>
                <div class="form-group-submit" #btnContinue>
                    <div class="fp-container-btn">
                        <button class="fp-btn-primary submit-btn" type="button" id="buttonContinue">
                            <span class="btn-content-text" id="btn-continue-cancel-account">
                                {{ "continue" | translate}}
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </ng-template>
</fp-card-body>