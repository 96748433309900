import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CreateVehiculeComponent } from "./create-vehicle.component";

export const createVehicleRootRoute = "create";
const routes: Routes = [
  {
    component: CreateVehiculeComponent,
    path: "",
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class CreateVehicleRoutingModule {}
