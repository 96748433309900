import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreModule } from "@core/core.module";
import { AutomaticMovementsComponent } from "./automatic-movements.component";

@NgModule({
  declarations: [AutomaticMovementsComponent],
  imports: [CommonModule, CoreModule],
  providers: [DatePipe],
  exports: [AutomaticMovementsComponent],
})
export class AutomaticMovementsModule {}
