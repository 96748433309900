import { IAddress } from "@app/modules/tag/models/address";

export const addressFeatureName = "addressReducer";

export const initialAddressState: IAddress = {
  body: null,
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
