import { Pipe, PipeTransform } from "@angular/core";
/**
 * Keys pipe
 */
@Pipe({
  name: "keys",
})
export class KeysPipe implements PipeTransform {
  /**
   * Transform method to change value
   *
   * @param value value to transform
   */
  transform(value: any): string[] {
    return Object.keys(value);
  }
}
