import { createAction } from "@ngrx/store";
import { IDataTermsandConditionsResponse } from "@singup/models/itermsand-conditions";

export const enum TypeActions {
  termsConditionsLoadString = "[TERMS AND CONDITIONS] Loading data",
  termsConditionsSaveString = "[TERMS AND CONDITIONS] Successful data loaded",
  termsConditionsErrorString = "[TERMS AND CONDITIONS] Save error response",
  setTermsandConditionsCheckRequiredString = "[TERMS AND CONDITIONS] Set check t&c required",
}
/**
 * Action to detect event loading terms and conditions data
 */
export const termsandConditionsLoad = createAction(
  TypeActions.termsConditionsLoadString,
);
/**
 * Action to detect event loaded terms and conditions data
 */
export const termsandConditionsSave = createAction(
  TypeActions.termsConditionsSaveString,
  (data: IDataTermsandConditionsResponse[]) => ({ data }),
);
/**
 * Action to set data error
 */
export const termsandConditionsError = createAction(
  TypeActions.termsConditionsErrorString,
  (error: any) => ({ error }),
);

/**
 * Action to set check required
 */
export const setTermsandConditionsCheckRequired = createAction(
  TypeActions.setTermsandConditionsCheckRequiredString,
  (value: boolean) => ({ value }),
);
