import { ILoaderState } from "@app/models/loader";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LoaderFeatureName } from "../state/loader.state";

export const loaderRootSelector =
  createFeatureSelector<ILoaderState>(LoaderFeatureName);

export const selectLoader = createSelector(
  loaderRootSelector,
  (state: ILoaderState) => state,
);
