<div class="text-center" [class.twoAction]="twoAction">
    <img [class.icon-check]="!warning" [class.icon-warning]="warning" [src]="icon" alt="Logo check">
    <h1 class="fp-h1 welcome-title" [class.big]="big" [class.twoAction]="twoAction"
        *ngIf="title && !title2 && !title3 && !title4">
        {{ title }}
    </h1>
    <h1 class="fp-h1 welcome-title" [class.big]="big" *ngIf="title && title2 && !title3 && !title4">
        {{ title }} <br class="welcome-separator"> {{ title2 }}
    </h1>
    <h1 class="fp-h1 welcome-title" *ngIf="title && title2 && title3 && !title4">
        {{ title }} {{ title2 }}
        <br class="welcome-separator-tabled"> {{ title3 }}
    </h1>
    <h1 class="fp-h1 welcome-title" *ngIf="title && title2 && title3 && title4">
        {{ title }} {{ title2 }}
        <br class="welcome-separator-tabled"> {{ title3 }} {{title4}}
    </h1>
    <h2 *ngIf="!twoAction" class="fp-body-one welcome-subtitle" [innerHtml]="messageSanitizer"></h2>
    <div *ngIf="twoAction" class="fp-page-space"></div>
    <div class="form-group">
        <div class="form-group-submit" [class.twoAction]="twoAction">
            <button class="submit-btn fp-btn-primary" type="button" id="buttonPageMessage" (click)="next('routerLink')">
                {{ labelButton }}
            </button>
            <button *ngIf="labelButtonSecond" (click)="next('secondAction')" class="submit-btn fp-btn-secondary"
                type="button" id="secondAction">
                {{ labelButtonSecond }}
            </button>
        </div>
    </div>
    <div #btnContinue>
        <div class="continue" *ngIf="labelBack" (click)="back()" id="returnLabelBack">
            <button class="fp-btn-ghost" type="button" id="secondAction">
                {{ labelBack }}
            </button>
        </div>
    </div>

</div>