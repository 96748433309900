import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { createRechargeRootRoute } from "./modules/recharge/recharge-routing.module";
import {
  createStatusTransactionRootRoute,
  createStatusTransactionRootRouteFreeTag,
} from "./modules/status/status-routing.module";

export const transactionsRootRoute = "transactions";

const routes: Routes = [
  {
    path: "",
    redirectTo: createRechargeRootRoute,
    pathMatch: "full",
  },
  {
    path: createRechargeRootRoute,
    loadChildren: () =>
      import("./modules/recharge/recharge.module").then(
        (m) => m.RechargeModule,
      ),
  },
  {
    path: createStatusTransactionRootRoute,
    loadChildren: () =>
      import("./modules/status/status.module").then((m) => m.StatusModule),
  },
  {
    path: createStatusTransactionRootRouteFreeTag,
    loadChildren: () =>
      import("./modules/status/status.module").then((m) => m.StatusModule),
  },
  {
    path: "**",
    redirectTo: createRechargeRootRoute,
    pathMatch: "full",
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransactionsRoutingModule {}
