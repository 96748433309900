import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PaymentFeatureName, PaymentModuleState } from "../state/payment.state";

export const PseBanksRootSelector =
  createFeatureSelector<PaymentModuleState>(PaymentFeatureName);

export const selectPseBanks = createSelector(
  PseBanksRootSelector,
  (state: PaymentModuleState) => state.pseBanksReducer,
);
