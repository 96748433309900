import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { IdentificationTypesService } from "@shared/services/identification-types.service";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as identificationTypesActions from "../actions/identification-types.action";

@Injectable()
export class IdentificationTypesEffect {
  loadDocumentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(identificationTypesActions.documentTypeLoad),
      switchMap(() =>
        this.identificationTypesService.getDocumentTypes("pn").pipe(
          map((documenTypes) =>
            identificationTypesActions.documentTypeSuccess({ documenTypes }),
          ),
          catchError((error) =>
            of(identificationTypesActions.documentTypeError({ error })),
          ),
        ),
      ),
    ),
  );
  constructor(
    private actions$: Actions,
    private identificationTypesService: IdentificationTypesService,
  ) {}
}
