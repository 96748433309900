<fp-card-body [template]="'secondTemplate'" class="create-vehicle-container" close="true"
    (actionClose)="actionClose($event)">
    <ng-template>
        <form novalidate [formGroup]="formCreateVehicle" (ngSubmit)="createVehicle()">
            <div class="vehicles-body">
                <h1 class="vehicles-title fp-h1">{{ "title" | translate }}</h1>
                <h2 class="vehicles-subtitle fp-body-one"> {{ "subtitle" | translate}}</h2>
                <div class="form-group">
                    <div class="line" *ngIf="showSpam === false">
                        <hr>
                    </div>
                    <div class="space-inputs" *ngIf="showSpam === false"> </div>
                    <fp-info-message [message]="'info_message' | translate " typeClass="info"
                        (emitCloseInfo)="closeInfoSpam()" [showInfo]="showSpam" [showClose]="false"></fp-info-message>
                    <div class="space-inputs"> </div>
                </div>
                <div class="content-input-vehicle">
                    <fp-input #licensePlate (change)="handleChange($event.target.value)"
                        class="form-group input-vehicle" labelClass="form-label" nameLabelTranslate="id_plaque"
                        [autoFocus]="false" viewSpaceInput="true" [viewErrorsInput]="true" [viewIconErrorInput]="true"
                        inputClass="input-large" typeInput="text" nameInput="licensePlate" idInput="licensePlate"
                        maxlength="10" formControlName="licensePlate"
                        [inputValidationMessages]="createVehicleValidationMessages.licensePlate"
                        [formControl]="formCreateVehicle.controls['licensePlate']" regexp="^[a-zA-Z0-9]{0,10}$"
                        matchRegexp="([a-zA-Z0-9]*)"></fp-input>
                </div>
                <div class="space-inputs"> </div>
                <div class="form-group">
                    <label class="form-label">{{ "type_class" | translate }}</label>
                    <div style="padding-top: 16px;"> </div>
                    <div class="cards-container">
                        <div class="card-container" *ngFor="let vehicleType of vehicleTypes; let itype= index">
                            <button class="vehicles-card"
                                [class.selected-option]="this.formCreateVehicle.controls.vehicleType.value === vehicleType.value"
                                type="button" (click)="chooseVehicleType(vehicleType.value)"
                                id="type{{vehicleType.value}}">
                                <div class="radio-btn-container">
                                    <fp-radiobutton class="fp-radio-vehicle"
                                        [internalLabel]="vehicleType.shortDescription"
                                        [class.fp-radiobutton-sub-text]="vehicleType.shortDescription"
                                        formControlName="vehicleType" name="vehicleType" [inputId]="'radio'+itype"
                                        [value]="vehicleType.value"></fp-radiobutton>
                                </div>
                                <div [class.card-img-sub-text]="vehicleType.shortDescription">
                                    <img [src]="vehicleType.icon.split(',')[0]" alt="icon-vehicle">
                                    <div class="card-container-sub-label"
                                        [style.visibility]="vehicleType.icon.split(',')[1] ? 'visible': 'hidden'">
                                        <label class="card-sub-label">{{vehicleType.icon.split(',')[1]}}</label>
                                    </div>
                                </div>
                            </button>
                            <label [class.big-text]="vehicleType.description.length > 13" class="card-label"
                                for="vehicleType">{{vehicleType.description}}</label>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-group-submit">
                <div class="fp-container-btn">
                    <button class="fp-btn-primary submit-btn" type="submit" id="buttonContinue"
                        [disabled]="!formCreateVehicle.valid">
                        <span class="btn-content-text">
                            {{ 'btn_continue' | translate }}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    </ng-template>
</fp-card-body>