<div class="fp-movement-row">

    
    <div class="fp-movement-description">
        <span class="fp-amount">
            ${{ financialProduct?.rechargeBalance | currency : 'COP' : '' : '1.0-0' }}
            <br>
            <span class="small-movement date">
                Valor de recarga
            </span>
        </span>
    </div>

    <div class="fp-movement-icon" [ngClass]="transactionClass">
        <img [src]="image" alt="tag compra">
    </div>

    <div class="text-right">
        <span class="fp-amount" ngClass="recharge">
            ${{ financialProduct?.lowBalance | currency : 'COP' : '' : '1.0-0' }}
            <br>
            <span class="small-movement date">
                Saldo minimo
            </span>
        </span>
    </div>
</div>
