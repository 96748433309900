import { createAction, props } from "@ngrx/store";
import { IAddressInfo } from "../../models/address";

export const enum TypeAddressInformationActions {
  tagSetAddressString = "[shipping] set address information",
}

export const setAddressInformation = createAction(
  TypeAddressInformationActions.tagSetAddressString,
  props<IAddressInfo>(),
);
